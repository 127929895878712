import { AxiosError } from 'axios';
import {
  OptionsObject, SnackbarKey, SnackbarMessage, VariantType,
} from 'notistack';
import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { User } from '../types';
import Locale, { LanguageCode } from '../locale';
import { DEFAULT_API_ERROR_MESSAGE } from './errors';

interface ResponseData {
  data?: {
    user: User;
  };
  message: string;
  success?: boolean;
  response?: {
    data: {
      errorMessage?: string;
    };
  }
}

const cache = createIntlCache();

const selectedLocale: LanguageCode = JSON.parse(localStorage?.getItem('settings') || '{"lang": "en"}').lang;
const intl = createIntl({
  locale: Locale.def[selectedLocale || 'en'],
  messages: Locale[selectedLocale],
}, cache);

const handleApiResponse = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  response: ResponseData | AxiosError,
  successSnackbar: boolean,
  variant: VariantType | null = null,
  action: React.ReactNode | null = null,
) => {
  if (successSnackbar) {
    enqueueSnackbar(intl.messages[response.message] ?? response.message, {
      variant: variant || 'success',
      action,
    });
  } else if (response.response) {
    const messageToShow = intl.messages[response.response.data?.message]
      || intl.messages[DEFAULT_API_ERROR_MESSAGE];
    enqueueSnackbar(messageToShow, {
      variant: variant || 'error',
      action,
    });
  } else if (response.message) {
    enqueueSnackbar(response.message, {
      variant: variant || 'error',
      action,
    });
  }
};

export default handleApiResponse;
