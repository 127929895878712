import React, {
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { Check, Edit, ManageAccounts } from '@mui/icons-material';
import { persistorFlush } from '../../../store';

import { logout, setUserData } from '../../../actions/accountActions';

import useStyles from './styles';
import { ReduxState } from '../../../types';
import { resetDashboard } from '../../../actions/dashboardActions';
import handleApiResponse from '../../../utils/handleApiResponse';
import { putUser } from '../../../requests/api/users';

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state: ReduxState) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState<Record<string, { value: string, editMode: boolean }>>({
    nameTag: {
      value: '',
      editMode: false,
    },
    country: {
      value: '',
      editMode: false,
    },
  });
  const [isOpen, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    const auxUserInfo = Object.entries(userInfo).reduce((acc, val) => ({
      ...acc,
      [val[0]]: {
        value: '',
        editMode: false,
      },
    }), {});
    setOpenModal(false);
    setUserInfo(auxUserInfo);
  };

  const handleEditMode = (source: string) => {
    const auxUserInfo = Object.entries(userInfo).reduce((acc, val) => {
      if (val[0] === source) {
        return {
          ...acc,
          [val[0]]: {
            value: userInfo[source].value,
            editMode: true,
          },
        };
      }
      return {
        ...acc,
        [val[0]]: {
          value: userInfo[val[0]].value,
          editMode: false,
        },
      };
    }, {});
    setUserInfo(auxUserInfo);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, source: string,
  ) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [source]: {
        ...prevState[source],
        value: e.target.value,
      },
    }));
  };

  const handleLogout = async () => {
    try {
      handleClose();
      dispatch(logout());
      dispatch(resetDashboard());
      await persistorFlush();
      history.push('/');
    } catch (error) {
      enqueueSnackbar('No se pudo cerrar sesión.', {
        variant: 'error',
      });
    }
  };

  const handleConfirmChange = async () => {
    if (userInfo.nameTag.value.trim() === '' && userInfo.country.value.trim() === '') {
      handleApiResponse(enqueueSnackbar, { message: 'No changes were made.' }, false);
      return;
    }

    try {
      if (account.user) {
        const userInfoUpdate = {
          id: account.user?.id,
          nameTag: userInfo.nameTag.value !== '' ? userInfo.nameTag.value : account.user?.username,
          country: userInfo.country.value !== '' ? userInfo.country.value.toLowerCase() : account.user?.country,
        };
        const response = await putUser(userInfoUpdate);

        dispatch(setUserData({
          ...account.user,
          username: userInfoUpdate.nameTag,
          country: userInfoUpdate.country,
        }));
        handleCloseModal();
        handleApiResponse(enqueueSnackbar, response, true);
      }
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return <>
    <Box
      display="flex"
      alignItems="center"
      component={ButtonBase}
      onClick={handleOpen}
      ref={ref}
    >
      <Avatar
        alt="User"
        className={classes.avatar}
      />
      <Hidden mdDown>
        <Typography
          variant="h6"
          className={classes.textColor}
        >
          {`${account.user?.username}`}
        </Typography>
      </Hidden>
    </Box>
    <Menu
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      PaperProps={{ className: classes.popover }}
      anchorEl={ref.current}
      open={isOpen}
    >
      <MenuItem
        onClick={() => {
          setOpenModal(true);
          handleClose();
        }}
      >
        <FormattedMessage id='navigation.topbar.account.edit' />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <FormattedMessage id='navigation.topbar.account.closeSession' />
      </MenuItem>
    </Menu>
    <Modal
      open={openModal}
      onClose={handleCloseModal}
    >
      <Card className={classes.modalCardWrapper}>
        <CardContent className={classes.modalCardContentWrapper}>
          <Box className={classes.modalHeader}>
            <Box className={classes.iconAndTitleBox}>
              <ManageAccounts fontSize='large' />
              <Box className={classes.titleHeader}>
                <Typography
                  className={classes.titleTypography}
                >
                  Manage profile information
                </Typography>
              </Box>
            </Box>
            <IconButton onClick={handleCloseModal} >
              X
            </IconButton>
          </Box>
          <Divider className={classes.modalDivider} />
          <Box className={classes.textFieldsContainer}>
            {!userInfo.nameTag.editMode
              && <Box className={classes.noEditModeContainer}>
                <Box className={classes.noEditModeLabelAndTitleContainer}>
                  <Typography
                    className={classes.labelTypography}
                  >
                    Name tag
                  </Typography>
                  <Typography
                    className={classes.titleTypography}
                  >
                    {userInfo.nameTag.value ? userInfo.nameTag.value : account.user?.username}
                  </Typography>
                </Box>
                <IconButton onClick={() => handleEditMode('nameTag')}>
                  <Edit />
                </IconButton>
              </Box>}
            {userInfo.nameTag.editMode
              && <Box display='flex'>
                <TextField
                  label={'Name tag'}
                  // error={Boolean(error?.message)}
                  color="primary"
                  fullWidth
                  // helperText={error?.message ? error?.message : ''}
                  margin="normal"
                  name="nameTag"
                  onChange={(e) => handleChange(e, 'nameTag')}
                  type="text"
                  value={userInfo.nameTag.value}
                  variant="outlined"
                />
                <IconButton onClick={() => setUserInfo((prevState) => ({
                  ...prevState,
                  nameTag: {
                    ...prevState.nameTag, editMode: false,
                  },
                }))}>
                  <Check />
                </IconButton>
              </Box>}
            <Divider className={classes.modalDivider} />
            {!userInfo.country.editMode
              && <Box className={classes.noEditModeContainer}>
                <Box className={classes.noEditModeLabelAndTitleContainer}>
                  <Typography
                    className={classes.labelTypography}
                  >
                    Country
                  </Typography>
                  <Typography
                    className={classes.titleTypography}
                  >
                    {userInfo.country.value !== ''
                      ? userInfo.country.value.toUpperCase()
                      : account.user?.country.toUpperCase()}
                  </Typography>
                </Box>
                <IconButton onClick={() => handleEditMode('country')}>
                  <Edit />
                </IconButton>
              </Box>}
            {userInfo.country.editMode
              && <Box display='flex'>
                <TextField
                  label={'Country'}
                  // error={Boolean(error?.message)}
                  color="primary"
                  fullWidth
                  // helperText={error?.message ? error?.message : ''}
                  margin="normal"
                  name="nameTag"
                  onChange={(e) => handleChange(e, 'country')}
                  type="text"
                  value={userInfo.country.value}
                  variant="outlined"
                />
                <IconButton onClick={() => setUserInfo((prevState) => ({
                  ...prevState,
                  country: {
                    ...prevState.country, editMode: false,
                  },
                }))}>
                  <Check />
                </IconButton>
              </Box>
            }
            <Divider className={classes.modalDivider} />
          </Box>
          <Box display='flex' justifyContent='space-between' marginTop='24px'>
            <Button
              className={classes.cancelButton}
              variant='contained'
              color='primary'
              fullWidth={true}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmButton}
              fullWidth={true}
              disabled={Boolean(userInfo.country.editMode || userInfo.nameTag.editMode)}
              onClick={handleConfirmChange}
            >
              Confirm change
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  </>;
}

export default Account;
