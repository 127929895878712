import axios from '../../utils/axios';

const getAllAvailableStocks = async ({ userId, sort }: Record<string, string | number>) => {
  const response = await axios.get(`/api/assets?userId=${userId}&sort=${sort}`);

  return response.data;
};

export {
  getAllAvailableStocks,
};
