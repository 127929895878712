import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  FlagOutlined,
  LocalFireDepartment,
  Person,
  PersonAddAlt,
  PersonRemove,
} from '@mui/icons-material';
import clsx from 'clsx';
import { Calendar } from 'react-feather';
import useStyles from './styles';
import { currencyFormatter, decimalsRound, dateFormatter } from '../../../utils/usefulFormatters';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  getFriendsFromUser,
  getUsersFilteredScoreboard,
  getUsersScoreboard,
  postNewFriends,
} from '../../../requests/api/users';
import { ReduxState, TransactionDetailResponse } from '../../../types';
import { setLeaders, setSelectedUserToGraph } from '../../../actions/dashboardActions';
import { getHoldingsResumeByUserId } from '../../../requests/api/holdings';

const INITIAL_BALANCE = 100000;

interface TransactionDetailResponseOneDay {
  cashBalance: number,
  portfolioBalance: any,
  username: string,
  startingDate: string,
  holdings: any,
  prices: any,

}

function LeaderBoard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [friendsFilter, setFriendsFilter] = useState(false);
  const [countryFilter, setCountryFilter] = useState(false);
  const [userFriends, setUserFriends] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortType, setSortType] = useState('standard');
  const [openDetailedModal, setOpenDetailedModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<TransactionDetailResponseOneDay>({
    holdings: {},
    prices: {},
    cashBalance: 0,
    portfolioBalance: {},
    username: '',
    startingDate: '',
  });
  const user = useSelector((state: ReduxState) => state.account.user);
  const dashboardState = useSelector((state: ReduxState) => state.dashboard);

  const postAddRemoveFriends = async (id: number) => {
    try {
      await postNewFriends(id);
      if (userFriends.includes(id)) {
        setUserFriends((prevState) => prevState.filter((item) => item !== id));
      } else {
        setUserFriends([...userFriends, id]);
      }
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleSeeMore = async (id: number) => {
    try {
      const { holdingsResume } = await getHoldingsResumeByUserId([id]);

      setSelectedDetail(holdingsResume[id]);
      setOpenDetailedModal(true);
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleOnCloseModal = () => {
    setSelectedDetail({
      holdings: {},
      prices: {},
      cashBalance: 0,
      portfolioBalance: {},
      username: '',
      startingDate: '',
    });
    setOpenDetailedModal(false);
  };

  useEffect(() => {
    if (user) {
      dispatch(setSelectedUserToGraph([user?.id]));
    }
  }, [user?.id]);

  useEffect(() => {
    const getFilteredLeaders = async () => {
      try {
        const { users } = await getUsersFilteredScoreboard({
          sort: sortType,
          isFriendFiltered: friendsFilter,
          isCountryFiltered: countryFilter,
        });

        const alreadySelectedUsers = dashboardState.userToGraph;
        const filteredUsersToGraph = users
          .filter((item: Record<string, number>) => alreadySelectedUsers.includes(item.id))
          .map((item: Record<string, number>) => item.id);
        dispatch(setSelectedUserToGraph(filteredUsersToGraph));
        dispatch(setLeaders(users));
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    const getAllLeaders = async () => {
      try {
        const { users } = await getUsersScoreboard({ sort: sortType });
        dispatch(setLeaders(users));
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    // Retrieves user's friends list
    const getAllFriends = async () => {
      try {
        const { friends } = await getFriendsFromUser();
        setUserFriends(friends);
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (friendsFilter || countryFilter) {
      getFilteredLeaders();
    } else {
      getAllLeaders();
    }
    getAllFriends();
  }, [
    sortType,
    user?.hasStarted,
    dashboardState.lastUpdatedDatePrice,
    friendsFilter,
    countryFilter,
  ]);

  const handleSelectOneUser = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let usersCount = 0;
    const users = dashboardState.userToGraph;
    const allLeadersIds = dashboardState.leaders.map((leader) => leader.id);
    if (!users.includes(id)) {
      const auxUsers = [...users, id];
      usersCount = auxUsers.length;
      dispatch(setSelectedUserToGraph(auxUsers));
    } else {
      const auxUsers = users.filter((selUser) => selUser !== id);
      usersCount = auxUsers.length;
      dispatch(setSelectedUserToGraph(auxUsers));
    }

    if (usersCount === allLeadersIds.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allLeadersIds = dashboardState.leaders.map((leader) => leader.id);
    if (event.currentTarget.checked) {
      dispatch(setSelectedUserToGraph([...allLeadersIds]));
      setSelectAll(true);
    } else {
      dispatch(setSelectedUserToGraph(
        allLeadersIds
          .filter((leaderId) => leaderId === user?.id),
      ));
      setSelectAll(false);
    }
  };

  const positionTile = (
    id: number,
    userName: string,
    country: string,
    balance: number,
    percentage: number,
    position: number,
  ) => {
    const colorToDisplay = balance > INITIAL_BALANCE ? '#00D293' : [balance < INITIAL_BALANCE ? '#D42F2F' : '#000002'];
    // eslint-disable-next-line no-nested-ternary
    const classesOnTile = user?.id === id ? classes.individualTileLoggedUser
      : (dashboardState.userToGraph.includes(id)
        ? classes.individualTileSelected
        : classes.individualTile);

    return (
      <Box
        key={position}
        className={classesOnTile}
      >
        <Box
          flex={1}
          className={classes.leaderBoardRankingNumber}
        >
          {`# ${position}`}
        </Box>
        <Box
          flex={3}
          display='flex'
          flexDirection='column'
        >
          <Typography
            className={classes.leaderBoardText}
            onClick={() => handleSeeMore(id)}
          >
            {user?.id === id ? `Me - ${user?.country.slice(0, 3).toUpperCase()}` : `${userName} - ${country?.slice(0, 3).toUpperCase()}`}
          </Typography>
          <Box
            display='flex'
          >
            <Box
              display='flex'
            >
              <Typography
                color={colorToDisplay}
                className={classes.leaderBoardNumber}
              >

                {currencyFormatter(balance)}
              </Typography>
              <Divider
                className={user?.id === id
                  ? classes.purpleVerticalDivider
                  : classes.verticalDivider
                }
                orientation='vertical'
              />
              <Typography
                className={classes.leaderBoardPercentage}
              >
                {percentage === 0 && `${(percentage * 100).toFixed(0)} %`}
                {percentage > 0 && `+ ${(percentage * 100).toFixed(2)} %`}
                {percentage < 0 && `${(percentage * 100).toFixed(2)} %`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          flex={1}
        >
          {id !== user?.id
            && <Box
              display='flex'
              alignItems='center'
            >
              {userFriends.includes(id)
                && <IconButton onClick={() => postAddRemoveFriends(id)}>
                  <PersonRemove
                    className={classes.iconAddRemoveFriend}
                  />
                </IconButton>

              }
              {!userFriends.includes(id)
                && <IconButton onClick={() => postAddRemoveFriends(id)}>
                  <PersonAddAlt
                    className={classes.iconAddRemoveFriend}
                  />
                </IconButton>
              }
              <Checkbox
                checked={dashboardState.userToGraph.includes(id)}
                onChange={(event) => handleSelectOneUser(event, id)}
              />
            </Box>}

        </Box>
      </Box>
    );
  };

  return (
    <Box marginRight='18px'>
      <Box className={classes.titleBalanceAndFilterBox}>
        <Box className={classes.titleBalanceBox}>
          <Icon className={classes.titleIcon}>
            <LocalFireDepartment />
          </Icon>
          <Typography
            className={classes.sectionTitle}
          >
            Top TILs
          </Typography>
        </Box>
        <Box display='flex' marginTop='14px'>
          <Box
            display='flex'
            flex={1}
            minWidth={'182px'}
          >
            <FormControl fullWidth>
              <InputLabel id="criteria-selection">Ranking Criteria</InputLabel>
              <Select
                labelId="criteria-selection"
                value={sortType}
                label="Ranking Criteria"
                onChange={(e) => setSortType(e.target.value)}
              >
                <MenuItem value='standard'>Standard</MenuItem>
                <MenuItem value='perDay'>% Adjusted Return</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            display='flex'
            marginLeft='40px'
          >
            <Button
              onClick={() => setCountryFilter((prevState) => !prevState)}
              className={countryFilter
                ? classes.leaderboardShowRacePlayers
                : clsx(classes.leaderboardShowRacePlayers, classes.leaderboardDisabled)}
            >
              <SvgIcon
                className={classes.iconLeaderboardRacePlayers}
              >
                <FlagOutlined />
              </SvgIcon>
            </Button>
            <Button
              onClick={() => setFriendsFilter((prevState) => !prevState)}
              className={friendsFilter
                ? classes.leaderboardShowFriends
                : clsx(classes.leaderboardShowFriends, classes.leaderboardDisabled)}
            >
              <SvgIcon
                className={classes.iconLeaderboardShowFriends}
              >
                <Person />
              </SvgIcon>
            </Button>
          </Box>
          <Box display='flex'>
            <Box className={selectAll
              ? classes.leaderboardSelectAll
              : clsx(classes.leaderboardSelectAll, classes.leaderboardDisabled)}>
              <Checkbox
                sx={{
                  color: 'white',
                  '&.Mui-checked': {
                    color: 'white',
                  },
                  // '&.MuiSvgIcon-root': {
                  //   fill: 'red',
                  // },
                }}

                checked={selectAll}
                onChange={(event) => handleSelectAll(event)}
              // className={countryFilter
              //   ? classes.leaderboardShowRacePlayers
              //   : clsx(classes.leaderboardShowRacePlayers, classes.leaderboardDisabled)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Card className={classes.cardLeaderBoard}>
          <CardContent className={classes.cardContentLeaderBoard}>
            {dashboardState.leaders.map((item) => positionTile(
              item.id,
              item.username,
              item.country,
              item.balance,
              item.percentage,
              item.position,
            ))}
          </CardContent>
        </Card>
      </Box>
      {/* Portfolio Modal */}
      <Modal
        open={openDetailedModal}
        onClose={handleOnCloseModal}
      >
        <Card className={classes.modalCardWrapper}>
          <CardContent className={classes.modalCardContentWrapper}>
            <Box className={classes.modalHeader}>
              <Box className={classes.iconAndTitleBox}>
                <Icon>
                  <Calendar />
                </Icon>
                <Box className={classes.titleHeader}>
                  <Typography
                    variant='h1'
                  >
                    {`${selectedDetail.username} | ${dateFormatter(Date.now(), 'day')} | Day 1`}
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleOnCloseModal} >
                X
              </IconButton>
            </Box>
            <Divider className={classes.modalDivider} />
            <Box className={classes.modalStockDescription}>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Total balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {/* {typeof xValues[selectedDay.point?.series.name]
                    ?.totalBalance[selectedDay.point.x] === 'number'
                    ? currencyFormatter(xValues[selectedDay.point?.series.name]
                      ?.totalBalance[selectedDay.point.x] || 0)
                    : currencyFormatter(xValues[selectedDay.point?.series.name]
                      ?.totalBalance[selectedDay.point.x].y || 0)
                  } */}
                  {currencyFormatter(selectedDetail.cashBalance + selectedDetail.portfolioBalance)}
                </Typography>
              </Box>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Account balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {/* {currencyFormatter(xValues[selectedDay.point?.series?.name]
                    ?.cashBalance[selectedDay.point.x] || 0)} */}
                    {currencyFormatter(selectedDetail.cashBalance)}
                </Typography>
              </Box>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Portfolio balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {/* {currencyFormatter(xValues[selectedDay.point?.series?.name]
                    ?.portfolioBalance[selectedDay.point.x] || 0)} */}
                    {currencyFormatter(selectedDetail.portfolioBalance)}
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.modalDivider} />
            {Object.keys(selectedDetail.holdings).length > 0
              && <TableContainer sx={{ maxHeight: 260, height: '100%' }}>
                <Table stickyHeader sx={{ backgroundColor: 'white' }}>
                  <TableHead>
                    <TableRow >
                      <TableCell className={classes.tableHeaderCell}>
                        Stock ticker
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Stock name
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Price EOD
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Num of stocks
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Total value
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        % of portfolio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDetail.holdings
                      && Object.values(selectedDetail.holdings)
                        .filter((asset: any) => asset.quantity > 0)
                        .map((
                          item: any,
                          index,
                        ) => {
                          const totalStockValue: number = item.quantity
                            * selectedDetail.prices[item.assetId];
                          return (
                            <TableRow
                              key={index}
                            >
                              <TableCell className={classes.tableBodyCell}>
                                {item.ticker}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {item.name}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`$ ${decimalsRound(selectedDetail.prices[item.assetId], 2)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`${decimalsRound(item.quantity, 4)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`$ ${decimalsRound(totalStockValue, 2)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {/* eslint-disable-next-line max-len */}
                                {/* {`${decimalsRound((totalStockValue / xValues[selectedDay.point?.series.name]
                                  ?.portfolioBalance[selectedDay.point.x]) * 100 || 0, 2)} %`} */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>

            }
            {Object.keys(selectedDetail.holdings).length === 0
              && <Box
                textAlign='center'
              >
                No holdings in the portfolio
              </Box>

            }
          </CardContent>
        </Card>
      </Modal>
    </Box>
  );
}

LeaderBoard.propTypes = {
  className: PropTypes.string,
};

export default LeaderBoard;
