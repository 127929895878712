import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: '30px 72px',
  },
  container: {
    padding: '0px',
    minHeight: '100%',
    display: 'flex',
  },
  modalCardWrapper: {
    width: '573px',
    height: '474px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  modalCardContentWrapper: {
    padding: '16px !important',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  modalStartRaceBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    maxWidth: '320px',
  },
  iconReference: {
    fontSize: '235px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  startRaceText: {
    fontSize: '50px',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    whiteSpace: 'normal',
  },
}));

export default useStyles;
