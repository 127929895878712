import {
  LoginRequest, UpdatePasswordRequest, UserData, UserVerificationRequest,
} from '../types';
import axios from '../../utils/axios';

const postLogin = async ({
  username, password,
}: LoginRequest) => {
  const response = await axios.post('/api/login', {
    username,
    password,
  });

  return response.data;
};

const postGmailLogin = async ({
  accessToken,
}: { [key: string]: string }) => {
  const response = await axios.get(`/api/gmail-login?accessToken=${accessToken}`);

  return response.data;
};

const postRegister = async ({
  name, lastname, email, role,
}: UserData) => {
  const response = await axios.post('/api/register', {
    name, lastname, email, role,
  });

  return response.data;
};

// RECOVER PASSWORD
const postRecoverPassword = async (email: string) => {
  const response = await axios.post('/api/recover-password', {
    email,
  });

  return response.data;
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }: UpdatePasswordRequest) => {
  const response = await axios.post('/api/reset-password', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }: UserVerificationRequest) => {
  const response = await axios.post('/api/user-verification', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

export {
  postLogin,
  postRegister,
  postGmailLogin,
  postRecoverPassword,
  updatePassword,
  userVerification,
};
