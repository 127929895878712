import axios from '../../utils/axios';

interface PostNewTradeRequest {
  userId: number,
  assetId: number,
  moneyToTrade: number,
  price: number,
  typeOperation: string,
  timezoneOffset: number,
}

const getHoldingsByUserId = async (userId: number | string) => {
  const response = await axios.get(`/api/holdings/${userId}`);

  return response.data;
};

const getHoldingsResumeByUserId = async (userIds: number[] | string[]) => {
  const formattedArray = JSON.stringify(userIds);
  const response = await axios.get(`/api/holdings-resume?userIds=${formattedArray}`);

  return response.data;
};

const getHistoricalHoldingsFromUsers = async (source: string, selectedUsers: number[]) => {
  const formattedArray = JSON.stringify(selectedUsers);
  const response = await axios.get(`/api/holdings-history?source=${source}&selectedUsers=${formattedArray}`);

  return response.data;
};

const getIndividualDateTransactions = async (relUser: number, relDate: string) => {
  const response = await axios.get(`/api/ind-transaction-history?relUser=${relUser}&relDate=${relDate}`);

  return response.data;
};

const getHistoricalDetailedHoldingsFromUsers = async (source: string, selectedUsers: number[]) => {
  const formattedArray = JSON.stringify(selectedUsers);
  const response = await axios.get(`/api/holdings-detailed-history?source=${source}&selectedUsers=${formattedArray}`);

  return response.data;
};

const postNewTrade = async ({
  userId, assetId, moneyToTrade, price, typeOperation, timezoneOffset,
}: PostNewTradeRequest) => {
  const response = await axios.post('/api/holdings/create', {
    userId, assetId, moneyToTrade, price, typeOperation, timezoneOffset,
  });

  return response.data;
};
export {
  getHoldingsByUserId,
  getHoldingsResumeByUserId,
  getHistoricalHoldingsFromUsers,
  getIndividualDateTransactions,
  getHistoricalDetailedHoldingsFromUsers,
  postNewTrade,
};
