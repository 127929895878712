import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import { ArrowDropDownCircleOutlined, QueryStats, ShoppingCart } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { XCircle } from 'react-feather';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { currencyFormatter, fixedFormatOnZero } from '../../../utils/usefulFormatters';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getAllAvailableStocks } from '../../../requests/api/assets';
import { ReduxState } from '../../../types';
import { postNewTrade } from '../../../requests/api/holdings';
import { newTransactionNotifier } from '../../../actions/dashboardActions';
import useSocket from '../../../hooks/useSocket';

interface AssetResponse {
  id?: number,
  ticker: string,
  name: string,
  lastPrice: number,
  Holding: null | {
    quantity: number,
  },
  accPortfolioValue: number,
  accStockReturn: number,
}

interface ErrorMessage {
  message: string | null;
}

function PortfolioTable() {
  const classes = useStyles();
  const user = useSelector((state: ReduxState) => state.account.user);
  const dispatch = useDispatch();
  useSocket(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const dashBoardState = useSelector((state: ReduxState) => state.dashboard);

  const [allStocks, setAllStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState<AssetResponse>({
    name: '', ticker: '', lastPrice: 0, Holding: null, accPortfolioValue: 0, accStockReturn: 0,
  });
  const [allStocksFilter, setAllStocksFilter] = useState(true);
  const [moneyToTrade, setMoneyToTrade] = useState<string>('');
  const [openBuySellModal, setOpenBuySellModal] = useState({ open: false, type: 'b' });
  const [error, setError] = useState<ErrorMessage>({ message: null });
  const [sort, setSort] = useState<string>('quantity|DESC');

  const handleOpenBuySellModal = (type: string, st: AssetResponse) => {
    setOpenBuySellModal({ open: true, type });
    setSelectedStock(st);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPattern = /^[0-9]+\.?([0-9]{1,2})?$/;
    setError({ message: null });

    if (
      (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
      || (e.nativeEvent as InputEvent).inputType === 'deleteContentForward'
    ) {
      if (e.target.value.length < 1) {
        setMoneyToTrade('');
        return;
      }
    }

    if (!regexPattern.test(e?.target?.value)) {
      return;
    }

    if (openBuySellModal.type === 'b') {
      if (Number(e?.target?.value) > dashBoardState.balance.cashBalance) {
        setError({ message: "You can't buy more than your cash balance" });
        return;
      }
    }

    if (openBuySellModal.type === 's') {
      if (!selectedStock.Holding?.quantity
        || Number(e?.target?.value) > (selectedStock.Holding?.quantity * selectedStock.lastPrice)) {
        setError({ message: "You can't sell more stocks that are in your portfolio" });
        return;
      }
    }

    const isDot = e?.target?.value.split('')[e?.target?.value.length - 1] === '.';
    if (isDot) {
      setMoneyToTrade(e?.target?.value ?? '');
    } else {
      const auxStringSplit = e?.target?.value.split('');
      if (auxStringSplit[auxStringSplit.length - 2] === '.' && auxStringSplit[auxStringSplit.length - 1] === '0') {
        setMoneyToTrade(e?.target?.value ?? '');
      } else {
        setMoneyToTrade(`${Number(e?.target?.value)}` ?? '');
      }
    }
  };

  const handleMinMax = (e: React.MouseEvent, source: string) => {
    if (source === 'min') {
      if (openBuySellModal.type === 'b') {
        if (dashBoardState.balance.cashBalance > selectedStock.lastPrice) {
          setMoneyToTrade(`${selectedStock.lastPrice}`);
        } else {
          setError({ message: 'You don\'t have enough balance to buy this stock' });
        }
      } else if (selectedStock.Holding && selectedStock.Holding?.quantity > 0) {
        setMoneyToTrade(`${selectedStock.lastPrice}`);
      } else {
        setError({ message: 'You don\'t have enough stocks to sell' });
      }
    } else if (openBuySellModal.type === 'b') {
      setMoneyToTrade(`${Math.round(dashBoardState.balance.cashBalance * 100) / 100}`);
    } else if (selectedStock.Holding && selectedStock.Holding?.quantity > 0) {
      setMoneyToTrade(`${Math.round(selectedStock.Holding?.quantity * selectedStock.lastPrice * 100)
        / 100}`);
    } else {
      setError({ message: 'You don\'t have enough stocks to sell' });
    }
  };

  const handleCloseModal = () => {
    setOpenBuySellModal({ ...openBuySellModal, open: false });
    setMoneyToTrade('');
    setSelectedStock({
      name: '', ticker: '', lastPrice: 0, Holding: null, accPortfolioValue: 0, accStockReturn: 0,
    });
    setError({ message: null });
  };

  const handleNewTrade = async (type: string) => {
    try {
      if (user && selectedStock.id) {
        const response = await postNewTrade({
          userId: user.id,
          assetId: selectedStock.id,
          moneyToTrade: Number(moneyToTrade),
          price: selectedStock.lastPrice,
          typeOperation: type,
          timezoneOffset: new Date().getTimezoneOffset(),
        });
        handleApiResponse(enqueueSnackbar, response, true);
        dispatch(newTransactionNotifier());
        handleCloseModal();
      } else {
        throw new Error('User or Stock not found');
      }
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
      handleCloseModal();
    }
  };

  const handleSort = (source: string) => {
    const sortSeparation = sort.split('|');
    const typeSort = sortSeparation[1] === 'ASC' ? 'DESC' : 'ASC';
    setSort(`${source}|${typeSort}`);
  };

  useEffect(() => {
    const allStocksRequest = async () => {
      if (user) {
        try {
          const response = await getAllAvailableStocks({ userId: user.id, sort });
          setAllStocks(response.assets);
          if (openBuySellModal.open) {
            const relStock: AssetResponse[] = response.assets
              .filter((item: AssetResponse) => item.id === selectedStock.id);
            setSelectedStock((prevState) => ({ ...prevState, lastPrice: relStock[0]?.lastPrice }));
          }
        } catch (err) {
          const e = err as Error;
          handleApiResponse(enqueueSnackbar, e, false);
        }
      } else {
        handleApiResponse(enqueueSnackbar, { message: 'No user found. Please try logging in again' }, false);
      }
    };

    allStocksRequest();
  }, [dashBoardState.newTransaction, dashBoardState.lastUpdatedDatePrice, sort]);

  return (
    <Box className={classes.myPortfolioContainer}>
      <Box className={classes.StocksAndMyPortfolioContainer}>
        <Box className={classes.titleBalanceBox}>
          <Icon className={classes.titleIcon}>
            <QueryStats />
          </Icon>
          <Typography
            className={classes.sectionTitle}
          >
            Stocks
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => setAllStocksFilter(true)}
            className={allStocksFilter
              ? classes.portfolioAllStocksSelected
              : clsx(classes.portfolioAllStocksSelected, classes.portfolioStocksDisabled)}
          >
            All stocks
          </Button>
          <Button
            onClick={() => setAllStocksFilter(false)}
            className={!allStocksFilter
              ? classes.portfolioMyStocksSelected
              : clsx(classes.portfolioMyStocksSelected, classes.portfolioStocksDisabled)}
          >
            My portfolio
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper} className={clsx(classes.root, classes.tableContainer)}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box component='span'>Stock name</Box>
                <IconButton color='secondary' onClick={() => handleSort('ticker')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'>Current price</Box>
                <IconButton color='secondary' onClick={() => handleSort('lastPrice')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'># of stocks in portfolio</Box>
                <IconButton color='secondary' onClick={() => handleSort('quantity')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'>Portfolio value</Box>
                <IconButton color='secondary' onClick={() => handleSort('portValue')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'>Performance on stock</Box>
                <IconButton color='secondary' onClick={() => handleSort('peformanceOnStock')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'>Total stock allocations</Box>
                <IconButton color='secondary' onClick={() => handleSort('allocationPerAsset')}>
                  <ArrowDropDownCircleOutlined />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box component='span'>Sell/Buy</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allStocks
              .filter((item: any) => {
                if (!allStocksFilter) {
                  return item.Holding?.quantity > 0;
                }
                return item;
              })
              .map((st: AssetResponse) => {
                let relTicker = st.ticker;
                if (st.ticker === 'BRK.B') relTicker = 'BRK-B';
                return (<TableRow
                  key={st.ticker}
                >
                  <TableCell style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                      <Link to={{ pathname: `https://finance.yahoo.com/quote/${relTicker}?p=${relTicker}` }} target="_blank">{relTicker}</Link>
                    </span>
                    <span style={{ fontSize: '12px' }}>
                      {st.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    {st.lastPrice ? currencyFormatter(st.lastPrice) : 'No available price'}
                  </TableCell>
                  <TableCell>
                    {fixedFormatOnZero(st.Holding?.quantity, 4)}
                  </TableCell>
                  <TableCell>
                    {st.lastPrice ? currencyFormatter(st.lastPrice * (st.Holding?.quantity ?? 0)) : 'No available price'}
                  </TableCell>
                  <TableCell>
                    {st.Holding !== null ? `${fixedFormatOnZero(st.accStockReturn * 100, 2)} %` : null}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter(st.accPortfolioValue)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCellWithSpacing}
                  >
                    <Button
                      className={classes.sellButton}
                      onClick={() => handleOpenBuySellModal('s', st)}
                      disabled={Boolean(!st.lastPrice)}
                    >
                      SELL
                    </Button>
                    <Box width='8px' display='inline-block' />
                    <Button
                      className={classes.buyButton}
                      onClick={() => handleOpenBuySellModal('b', st)}
                      disabled={Boolean(!st.lastPrice)}
                    >
                      BUY
                    </Button>
                  </TableCell>
                </TableRow>);
              })}

          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={openBuySellModal.open}
        onClose={handleCloseModal}
      >
        <Card className={classes.modalCardWrapper}>
          <CardContent className={classes.modalCardContentWrapper}>
            <Box className={classes.modalHeader}>
              <Box className={classes.iconAndTitleBox}>
                <Icon>
                  <ShoppingCart />
                </Icon>
                <Box className={classes.titleHeader}>
                  <Typography
                    variant='h1'
                  >
                    {openBuySellModal.type === 'b' ? 'Buy Stock' : 'Sell Stock'}
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleCloseModal}>
                X
              </IconButton>
            </Box>
            <Divider className={classes.modalDivider} />
            <Box className={classes.modalStockDescription}>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Stock name
                </Typography>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color='textSecondary'
                    fontWeight={700}
                  >
                    {selectedStock.ticker}
                  </Typography>
                  <Typography
                    color='textSecondary'
                    fontWeight={500}
                    fontSize='12px'
                  >
                    {selectedStock.name}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Current stock price
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {currencyFormatter(selectedStock.lastPrice)}
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.modalDivider} />
            <Box display='flex' flexDirection='column'>
              {openBuySellModal.type === 'b' && (
                <Box display='flex' flexDirection='column'>
                  <Box display='flex'>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        Number of stocks owned
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {fixedFormatOnZero(selectedStock.Holding?.quantity, 4)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        Account Balance
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {currencyFormatter(dashBoardState.balance.cashBalance ?? 0)}
                      </Typography>
                    </Box>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography>
                        Stocks to be bought
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {Math.round((Number(moneyToTrade) / selectedStock.lastPrice) * 100) / 100}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {openBuySellModal.type === 's' && (
                <Box display='flex' flexDirection='column'>
                  <Box display='flex'>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        Number of stocks owned
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {fixedFormatOnZero(selectedStock.Holding?.quantity, 4)}
                      </Typography>
                    </Box>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        % of owned stocks to be sold
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {`${selectedStock.Holding?.quantity
                          ? (((selectedStock.Holding?.quantity * selectedStock.lastPrice)
                            / dashBoardState.balance.portfolioBalance) * 100).toFixed(2)
                          : 0} %`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        Account Balance
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {currencyFormatter(dashBoardState.balance.cashBalance ?? 0)}
                      </Typography>
                    </Box>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography>
                        Stocks to be sold
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {!!moneyToTrade && Number(moneyToTrade) % selectedStock.lastPrice !== 0
                          ? (Number(moneyToTrade) / selectedStock.lastPrice).toFixed(4)
                          : Number(moneyToTrade) / selectedStock.lastPrice}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Box className={classes.titleAndStockDescription}>
                      <Typography
                        fontWeight={500}
                      >
                        Amount ($) in stocks
                      </Typography>
                      <Typography
                        color='textSecondary'
                        fontWeight={700}
                      >
                        {currencyFormatter(
                          Number(fixedFormatOnZero(selectedStock.Holding?.quantity, 4))
                          * selectedStock.lastPrice,
                        )}
                      </Typography>
                    </Box>

                  </Box>
                </Box>
              )}
              <TextField
                label={`Total amount to ${openBuySellModal.type === 'b' ? 'buy' : 'sell'}`}
                error={Boolean(error?.message)}
                color="primary"
                fullWidth
                helperText={error?.message ? error?.message : ''}
                margin="normal"
                name="amountToTrade"
                onChange={handleChange}
                type="text"
                value={moneyToTrade}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <Typography
                      color='textPrimary'
                      fontSize='15px'
                    >
                      $
                    </Typography>
                  </InputAdornment>,
                  endAdornment: <Box className={classes.modalActionsInput}>
                    <IconButton onClick={() => setMoneyToTrade('')}>
                      <XCircle />
                    </IconButton>
                    <Button
                      className={classes.modalMinMaxButton}
                      variant='contained'
                      color='primary'
                      fullWidth={true}
                      onClick={(e) => handleMinMax(e, 'min')}
                    >
                      Min
                    </Button>
                    <Button
                      className={classes.modalMinMaxButton}
                      variant='contained'
                      color='primary'
                      fullWidth={true}
                      onClick={(e) => handleMinMax(e, 'max')}
                    >
                      Max
                    </Button>
                  </Box>,
                }}
              />

            </Box>
            <Box display='flex' gap='16px' mt={2}>
              <Button
                className={classes.cancelButton}
                variant='contained'
                color='primary'
                fullWidth={true}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              {openBuySellModal.type === 'b' && <Button
                fullWidth={true}
                className={classes.buyButton}
                disabled={Boolean(!moneyToTrade)}
                onClick={() => handleNewTrade('b')}
              >
                Buy
              </Button>}
              {openBuySellModal.type === 's' && <Button
                fullWidth={true}
                className={classes.sellButton}
                disabled={Boolean(!moneyToTrade)}
                onClick={() => handleNewTrade('s')}
              >
                Sell
              </Button>}
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Box>

  );
}

PortfolioTable.propTypes = {
  className: PropTypes.string,
};

export default PortfolioTable;
