import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import useStyles from './styles';

import handleApiResponse from '../../utils/handleApiResponse';

import { ClassNameProps } from '../types';
import authService from '../../services/authService';
import { loginSuccess } from '../../actions/accountActions';

function LoginForm({ className, ...rest }: ClassNameProps) {
  const intl = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().min(3).required(intl.messages['login.username.required'] as string),
        password: Yup.string().max(255).required(intl.messages['login.password.required'] as string),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          const response = await authService.loginWithUsernameAndPassword(
            values.username, values.password,
          );
          if (response.success) {
            dispatch(loginSuccess(response.user));
          }
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className, classes.formContainer)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.username && errors.username)}
            color="primary"
            fullWidth
            helperText={touched.username && errors.username}
            label={intl.messages['login.username']}
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.username}
            variant="outlined"
            InputLabelProps={{ style: { fontFamily: 'Poppins, sans-serif' } }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="primary"
            helperText={touched.password && errors.password}
            label={intl.messages['login.password']}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            InputLabelProps={{ style: { fontFamily: 'Poppins, sans-serif' } }}
          />
          <Box mt={2}>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Sign In
            </Button>
          </Box>
        </form>
      )
      }
    </Formik >
  );
}

export default LoginForm;
