/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import {
  postGmailLogin,
  postLogin, postRegister,
} from '../requests/api/auth';
import { getUserById } from '../requests/api/users';
import { UNKNOWN_ERROR } from '../constants/errors';
import { User } from '../types';
import { INVALID_TOKEN, NO_TOKEN } from '../utils/errors';

interface JWTToken {
  exp: number;
  id: string;
  user: User;
}

const getAccessToken = (): string => localStorage.getItem('accessToken') || '';

const setSession = (accessToken: string) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAxiosInterceptors = ({ onLogout }: { onLogout: () => void }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Logout directo a la pantalla de login solo se daria si es que backend manda
      // NO_TOKEN o INVALID_TOKEN
      if (error.response && (
        error.response.data.authorizationError === NO_TOKEN
        || error.response.data.authorizationError === INVALID_TOKEN
      )) {
        if (onLogout) {
          setSession('');
          onLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

const loginWithUsernameAndPassword = async (username: string, password: string) => {
  const response = await postLogin({
    username,
    password,
  });
  let user;
  let token;
  if (response.success && response.user) {
    ({ token, user } = response);
    setSession(token);
    return {
      user,
      success: true,
    };
  }
  setSession('');
  return {
    success: false,
    errorMessage: response.errorMessage,
  };
};

const logoutSession = () => {
  setSession('');
};

const loginWithGmail = async (accessToken: string) => {
  const response = await postGmailLogin({ accessToken });

  let user;
  let token;
  if (response.success && response.user) {
    ({ token, user } = response);
    setSession(token);
    return {
      user,
      success: true,
    };
  }
  setSession('');
  return {
    success: false,
    errorMessage: response.errorMessage,
  };
};

const loginInWithToken = async () => {
  const accessToken = getAccessToken();
  const { id } = jwtDecode<JWTToken>(accessToken).user;
  try {
    const response = await getUserById(id);
    if (!response.success) {
      logoutSession();
      return null;
    }
    return (response.user) || null;
  } catch (err) {
    return null;
  }
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<JWTToken>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleAuthentication = () => {
  // localStorage.clear();
  const accessToken = getAccessToken();
  if (!accessToken) {
    setSession('');
    return;
  }

  if (isValidToken(accessToken)) {
    setSession(accessToken);
  } else {
    setSession('');
  }
};

const register = async (
  name: string, lastname: string, email: string,
) => {
  const role = 'admin';
  try {
    const response = await postRegister({
      name, lastname, email, role,
    });

    if (response.success) {
      return {
        success: true,
      };
    }
    return {
      success: false,
      errorMessage: response.message,
    };
  } catch (err) {
    return {
      success: false,
      errorMessage: UNKNOWN_ERROR,
    };
  }
};

const isAuthenticated = () => !!getAccessToken();

export default {
  setAxiosInterceptors,
  loginWithUsernameAndPassword,
  loginInWithToken,
  loginWithGmail,
  logoutSession,
  handleAuthentication,
  isAuthenticated,
  setSession,
  register,
};
