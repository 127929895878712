import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.background.component,
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
  },
  myPortfolioContainer: {
    marginTop: '30px',
  },
  StocksAndMyPortfolioContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  portfolioAllStocksSelected: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: theme.palette.background.default,
    padding: '0px',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    maxWidth: '110px',
    minWidth: '110px',
    height: '40px',
    textTransform: 'capitalize',
    marginRight: '3px',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.6)',
    },
  },
  portfolioMyStocksSelected: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: theme.palette.background.default,
    padding: '0px',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    maxWidth: '110px',
    minWidth: '110px',
    height: '40px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.6)',
    },
  },
  portfolioStocksDisabled: {
    backgroundColor: '#BDBDBD',
    '&:hover': {
      backgroundColor: 'rgba(189, 189, 189, 0.6)',
    },
  },
  tableContainer: {
    marginTop: '24px',
  },
  titleBalanceAndFilterBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  titleBalanceBox: {
    display: 'flex',
    color: theme.palette.text.secondary,
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: '8px',
  },
  cardComponent: {
    width: '100%',
    height: '160px',
    borderRadius: '5px',
    marginTop: '30px',
    display: 'flex',
    backgroundColor: theme.palette.background.component,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  individualCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  balanceHeaderTitle: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '20px',
    color: theme.palette.text.primary,
  },
  numberTitle: {
    fontWeight: 'bold',
    fontFamily: 'Inter, sans-serif',
    fontSize: '50px',
    color: theme.palette.text.secondary,
    padding: '10px',
  },
  tableCellWithSpacing: {
    gap: '8px',
  },
  sellButton: {
    backgroundColor: '#D42F2F',
    borderRadius: '100px',
    color: theme.palette.text.tertiary,
    '&:hover': {
      backgroundColor: 'rgba(212, 47, 47, 0.6)',
    },
  },
  buyButton: {
    backgroundColor: '#00D293',
    borderRadius: '100px',
    color: theme.palette.text.tertiary,
    '&:hover': {
      backgroundColor: 'rgba(0, 210, 147, 0.6)',
    },
  },
  cancelButton: {
    borderRadius: '100px',
  },
  individualTile: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100px',
    margin: '0px -16px',
    padding: '16px',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.15)',
    },
  },
  individualTileSelected: {
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    minHeight: '100px',
    margin: '0px -16px',
    padding: '16px',
    backgroundColor: 'rgba(119, 56, 237, 0.15)',
  },
  individualTileLoggedUser: {
    fontWeight: 700,
    display: 'flex',
    color: theme.palette.text.secondary,
    alignItems: 'center',
    minHeight: '100px',
    margin: '0px -16px',
    padding: '16px',
    borderLeft: '5px solid',
    borderLeftColor: theme.palette.text.secondary,
  },
  verticalDivider: {
    border: '1.5px solid',
    borderColor: theme.palette.text.primary,
    margin: '0px 5px',
  },
  purpleVerticalDivider: {
    border: '1.5px solid',
    borderColor: theme.palette.text.secondary,
    margin: '0px 5px',
  },
  cardLeaderBoard: {
    maxHeight: '1000px',
    overflowY: 'auto',
  },
  cardContentLeaderBoard: {
    backgroundColor: theme.palette.background.component,
    paddingTop: '0px',
    paddingBottom: '0px !important',
  },
  leaderboardShowRacePlayers: {
    backgroundColor: theme.palette.background.default,
    padding: '0px',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    maxWidth: '50px',
    minWidth: '50px',
    height: '50px',
    marginRight: '3px',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.6)',
    },
  },
  leaderboardShowFriends: {
    backgroundColor: theme.palette.background.default,
    padding: '0px',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    maxWidth: '50px',
    minWidth: '50px',
    height: '50px',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.6)',
    },
  },
  leaderboardSelectAll: {
    backgroundColor: theme.palette.background.default,
    marginLeft: '8px',
    padding: '0px',
    borderRadius: '100%',
    maxWidth: '50px',
    minWidth: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(119, 56, 237, 0.6)',
    },
  },
  leaderboardDisabled: {
    backgroundColor: '#BDBDBD',
    '&:hover': {
      backgroundColor: 'rgba(189, 189, 189, 0.6)',
    },
  },
  iconAddRemoveFriend: {
    color: theme.palette.background.default,
    fontSize: '26px',
  },
  iconLeaderboardRacePlayers: {
    fontSize: '35px',
    color: 'white',
  },
  iconLeaderboardShowFriends: {
    fontSize: '35px',
    color: 'white',
  },
  leaderBoardRankingNumber: {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
  },
  leaderBoardText: {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  leaderBoardNumber: {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 'bold',
    minWidth: '120px',
  },
  leaderBoardPercentage: {
    fontSize: '20px',
    fontFamily: 'Poppins, sans-serif',
  },
  modalCardWrapper: {
    width: '573px',
    height: '474px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  modalCardContentWrapper: {
    height: '100%',
    padding: '16px !important',
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  tableHeaderCell: {
    fontSize: '10px',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: 'white',
  },
  tableBodyCell: {
    fontSize: '10px',
    fontFamily: 'Poppins, sans-serif',
  },
  portfolioTableTableCellHeader: {
    position: 'relative',
  },
  iconAndTitleBox: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleHeader: {
    marginLeft: '12px',
  },
  modalDivider: {
    margin: '12px 0px',
    borderColor: theme.palette.primary.main,
    border: '1.5px solid',
    borderRadius: '8px',
  },
  modalStockDescription: {
    display: 'flex',
  },
  modalActionsInput: {
    display: 'flex',
    spacing: '25px',
  },
  modalMinMaxButton: {
    borderRadius: '100px',
  },
  titleAndStockDescription: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  chartCardContainer: {
    margin: '20px 0px',
  },
  chartBackgroundColor: {
    backgroundColor: theme.palette.background.component,
  },
  chartContentLeaderBoard: {
    padding: '0px !important',
  },
  sectionTitle: {
    fontSize: '28px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  },
  chartContainerBox: {
    marginTop: '30px',
  },
}));

export default useStyles;
