import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { THEMES } from '../../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  textColor: {
    color: theme.palette.text.tertiary,
  },
  root: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.primary.main,
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {}),
  },
  toolbar: {
    minHeight: 69,
    backgroundColor: theme.palette.primary.main,
    padding: '0px 72px',
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popoverSettings: {
    width: 320,
    padding: theme.spacing(2),
  },
  modalCardWrapper: {
    width: '573px',
    height: '360px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  modalCardContentWrapper: {
    padding: '16px !important',
    '&:focus-visible': {
      outline: 'none !important',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleHeader: {
    marginLeft: '12px',
  },
  modalDivider: {
    margin: '12px 0px',
    borderColor: theme.palette.primary.main,
    border: '1.5px solid',
    borderRadius: '8px',
  },
  iconAndTitleBox: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  textFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  noEditModeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noEditModeLabelAndTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelTypography: {
    fontSize: '16px',
    fontFamily: 'Poppins, sans-serif',
  },
  titleTypography: {
    fontSize: '28px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  cancelButton: {
    borderRadius: '100px',
    maxWidth: '240px',
    textTransform: 'capitalize',
  },
  confirmButton: {
    borderRadius: '100px',
    backgroundColor: '#00D293',
    maxWidth: '240px',
    color: theme.palette.text.tertiary,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(0, 210, 147, 0.6)',
    },
  },
}));

export default useStyles;
