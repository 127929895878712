import moment, { Moment } from 'moment';

const thousandSeparator = (
  num: number | string, separator: string,
) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const currencyFormatter = (num: number) => {
  if (num !== 0) {
    return `$ ${num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return '$ 0';
};

const twoDatesDiff = (iniDate: string | null, date: string | null) => {
  if (!date || !iniDate) {
    return 1;
  }

  const today = new Date(Number(date)).valueOf();
  const startDate = new Date(Number(iniDate)).valueOf();
  return Math.ceil((today - startDate + 1) / (1000 * 60 * 60 * 24));
};

const dateFormatter = (timestamp: string | number, source: string) => {
  if (source === 'day') {
    const auxFormat = Number(timestamp);
    const month = (new Date(auxFormat).getMonth() + 1) < 10
      ? `0${new Date(auxFormat).getMonth() + 1}`
      : `${new Date(auxFormat).getMonth() + 1}`;
    const day = (new Date(auxFormat).getDate()) < 10
      ? `0${new Date(auxFormat).getDate()}`
      : `${new Date(auxFormat).getDate()}`;
    return `${day} - ${month}`;
  }

  const formatedDate = moment(timestamp).format('DD-MM-YY HH:mm');

  return `${formatedDate}`;
};

function decimalsRound(num: number | string, decimals: number): number {
  const localNum = Number(num);
  const roundedTo = 10 ** decimals;
  return Math.round(localNum * roundedTo) / roundedTo;
}

function calculateNextInterval(originalDate: Moment, intervalToRound: number) {
  const actualMins = originalDate.minutes();
  const localDate = originalDate;
  if (actualMins > intervalToRound) {
    return localDate.startOf('hour').add(1, 'hours');
  }
  return localDate.startOf('hour').add(intervalToRound, 'minutes');
}

function getInitialDatePassingUTC(dateTime: string | number) {
  // Function to pass a timestamp (usually UTC), get initial date of it, and transform it to
  // browser tz
  const auxDate = new Date(Number(dateTime));
  const utcDate = auxDate.getUTCDate();
  const utcMonth = auxDate.getUTCMonth();
  const utcYear = auxDate.getUTCFullYear();

  return new Date(utcYear, utcMonth, utcDate).getTime();
}

function fixedFormatOnZero(numToFormat:number | undefined, decimals:number) {
  if (!numToFormat) return 0;

  if (Number(numToFormat) === 0) {
    return numToFormat;
  }
  return numToFormat.toFixed(decimals);
}

export {
  currencyFormatter,
  thousandSeparator,
  dateFormatter,
  twoDatesDiff,
  decimalsRound,
  calculateNextInterval,
  getInitialDatePassingUTC,
  fixedFormatOnZero,
};
