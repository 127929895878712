import { Leaders } from '../types';

export const SET_BALANCE = '@holdings/set-balance';
export const SET_SELECTED_USER_TO_GRAPH = '@holdings/setSelectedUserToGraph';
export const NEW_TRANSACTION_NOTIFIER = '@holdings/new-transaction-notifier';
export const NEW_PRICE_NOTIFIER = '@holdings/new-price-notifier';
export const RESET_DASHBOARD = '@holdings/reset-dashboard';
export const SET_LEADERS = '@leaderboard/set-leaders';

export const setBalance = (data: { [key: string]: number }) => ({
  type: SET_BALANCE,
  payload: data,
});

export const setSelectedUserToGraph = (data: number[]) => ({
  type: SET_SELECTED_USER_TO_GRAPH,
  payload: data,
});

export const newTransactionNotifier = () => ({
  type: NEW_TRANSACTION_NOTIFIER,
});

export const newPriceNotifier = (data: number[]) => ({
  type: NEW_PRICE_NOTIFIER,
  payload: data,
});

export const resetDashboard = () => ({
  type: RESET_DASHBOARD,
});

export const setLeaders = (data: Leaders[]) => ({
  type: SET_LEADERS,
  payload: data,
});
