import { useSelector } from 'react-redux';
import React from 'react';
import { Redirect } from 'react-router';
import { ChildrenProps, ReduxState } from '../../types';
import AuthService from '../../services/authService';

function AuthGuard({ children }: ChildrenProps): JSX.Element {
  const account = useSelector((state: ReduxState) => state.account);
  if (!account.user) {
    return <Redirect to="/login" />;
  }
  AuthService.handleAuthentication();
  if (!AuthService.isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
}

export default AuthGuard;
