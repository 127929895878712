import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SplashScreen from '../Loading/SplashScreen';
import AuthService from '../../services/authService';
import { persistorFlush } from '../../store';
import { logout, setUserData } from '../../actions/accountActions';
import { ChildrenProps } from '../../types';
import { resetDashboard } from '../../actions/dashboardActions';

function Auth({ children }: ChildrenProps): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      AuthService.setAxiosInterceptors({ onLogout: () => dispatch(logout()) });
      AuthService.handleAuthentication();
      if (AuthService.isAuthenticated()) {
        const user = await AuthService.loginInWithToken();
        if (user) {
          dispatch(setUserData(user));
          await persistorFlush();
        } else {
          dispatch(logout());
          dispatch(resetDashboard());
          await persistorFlush();
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}

export default Auth;
