import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Icon,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { Person } from '@mui/icons-material';
import useStyles from './styles';
import handleApiResponse from '../../../utils/handleApiResponse';
import { USER_ADMIN } from '../../../constants/users';
import { restartRace } from '../../../requests/api/users';

function Admin() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleRestartRace = async () => {
    try {
      const response = await restartRace(USER_ADMIN);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return (
    <Box>
      <Box className={classes.titleBalanceBox}>
        <Icon className={classes.titleIcon}>
          <Person />
        </Icon>
        <Typography
          className={classes.sectionTitle}
        >
          Admin
        </Typography>
      </Box>
      <Button
        variant='contained'
        onClick={handleRestartRace}
      >
        Start race again
      </Button>
    </Box>
  );
}

Admin.propTypes = {
  className: PropTypes.string,
};

export default Admin;
