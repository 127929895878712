// Every change here needs to be changed as well in server/misc/const/success.ts
// Success messages
const USER_VERIFIED = 'Usuario verificado correctamente';
const SUCCESSFUL_LOGIN = 'Login exitoso';
const RECOVER_PASSWORD_MAIL_SENT = 'Correo de recuperar contraseña enviado';
const PASSWORD_UPDATED = 'Contraseña actualizada';
const USER_CREATED = 'Usuario creado con éxito';
const USERS_FOUND = 'Usuarios encontrados exitosamente';
const USER_EDITED = 'Usuario editado exitosamente';
const USER_FOUND = 'Usuario encontrado';
const USERS_DELETED = 'Usuario/s eliminado/s exitosamente';

export {
  USER_VERIFIED,
  SUCCESSFUL_LOGIN,
  RECOVER_PASSWORD_MAIL_SENT,
  PASSWORD_UPDATED,
  USER_CREATED,
  USERS_FOUND,
  USER_EDITED,
  USER_FOUND,
  USERS_DELETED,
};
