import React, { useEffect, useState } from 'react';
import {
  Container, Box, Modal, Card, CardContent, Typography, SvgIcon,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useDispatch, useSelector } from 'react-redux';
import { SportsScore } from '@mui/icons-material';
import Page from '../../components/Layout/Page';
import Balance from './Components/Balance';

import handleApiResponse from '../../utils/handleApiResponse';

import useStyles from './styles';
import { getHoldingsByUserId } from '../../requests/api/holdings';
import { ReduxState } from '../../types';
import PortfolioTable from './Components/PortfolioTable';
import BalanceChart from './Components/BalanceChart';
import LeaderBoard from './Components/LeaderBoard';
import { putUserStartRace } from '../../requests/api/users';
import { loginSuccess } from '../../actions/accountActions';
import { USER_ADMIN } from '../../constants/users';
import Admin from './Components/Admin';

function UserBalance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxState) => state.account.user);

  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(Boolean(!user?.hasStarted
    && user?.username !== USER_ADMIN));

  const handleClose = (e: React.ChangeEvent<HTMLInputElement> | object, reason: string) => {
    if (reason !== 'backdropClick') {
      setOpenModal(false);
    }
  };

  const handleStartRace = async () => {
    if (user) {
      try {
        const response = await putUserStartRace({ id: user.id });
        dispatch(loginSuccess(response.user));
        handleClose({}, 'startRace');
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    } else {
      handleApiResponse(enqueueSnackbar, { message: 'Can\'t find user information. Please try logging in again' }, false);
    }
  };

  useEffect(() => {
    const holdingsByUserId = async (userId: number) => {
      try {
        await getHoldingsByUserId(userId);
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (user) {
      holdingsByUserId(user.id);
    }
  }, [user?.hasStarted]);

  return (
    <Page
      className={classes.root}
      title="Dashboard TIL | 2WC"
    >
      <Container
        className={classes.container}
        maxWidth={false}>
        <Box
          display='flex'
          flexDirection='column'
          flex={1}
        >
          {user && user.username !== USER_ADMIN && (
          <>
            <Balance />
            <BalanceChart />
            <PortfolioTable />
          </>
          )}
        {user && user.username === USER_ADMIN && (
            <Admin />
        )}
        </Box>
        <Box
          minWidth={410}
          ml={3}
        >
        {user && user.username !== USER_ADMIN && (
         <LeaderBoard />
        )}
        </Box>
      </Container>
      <Modal
        open={openModal}
        onClose={handleClose}
      >
        <Card className={classes.modalCardWrapper}>
          <CardContent className={classes.modalCardContentWrapper}>
            <Box
              className={classes.modalStartRaceBox}
            >
              <SvgIcon
                className={classes.iconReference}
                onClick={handleStartRace}
              >
                <SportsScore fontSize='large' />
              </SvgIcon>
              <Typography
                variant='h1'
                className={classes.startRaceText}
              >
                Click on flag to start race
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Page>
  );
}

export default UserBalance;
