import {
  DEFAULT_API_ERROR_MESSAGE,
  EXPIRED_LINK,
  GMAIL_CONNECTION_ERROR,
  GMAIL_USER_NOT_FOUND,
  INVALID_ARGUMENT,
  INVALID_ROLE,
  INVALID_TOKEN,
  NO_TOKEN,
  NO_USERS_FOUND,
  PASSWORDS_DO_NOT_MATCH,
  PROHIBITTED,
  REQUIRED_EMAIL,
  REQUIRED_TOKEN,
  TOKEN_NOT_GENERATED,
  USED_INVALID_TOKEN,
  USER_ALREADY_EXIST,
  USER_DOES_NOT_EXIST,
  USER_INACTIVE,
  USER_NOT_REGISTERED,
  VERIFICATION_MAIL_ERROR,
  WRONG_CREDENTIALS,
} from '../utils/errors';

import {
  PASSWORD_UPDATED,
  RECOVER_PASSWORD_MAIL_SENT,
  SUCCESSFUL_LOGIN,
  USERS_DELETED,
  USERS_FOUND,
  USER_CREATED,
  USER_EDITED,
  USER_FOUND,
  USER_VERIFIED,
} from '../utils/success';

export default {
  'login.title': 'Ingresa',
  'login.subtitle': 'Ingresa a la plataforma de backoffice',
  'login.username': 'Nombre usuario',
  'login.username.required': 'Nombre usuario requerido',
  'login.password': 'Contraseña',
  'login.password.required': 'Contraseña requerida',
  'login.login': 'Iniciar sesión',
  'login.or': 'o',
  'login.continueGoogle': 'Continua con google',
  'login.forgottenPassword': '¿Olvidaste tu contraseña?',
  'admin.listView.header.title': 'Usuarios',
  'admin.listView.header.button.addUser': 'Agregar Usuario',
  'admin.listView.results.sort.filter.title': 'Orden',
  'admin.listView.results.sort.filter.newestFirst': 'Mas nuevo primero',
  'admin.listView.results.sort.filter.oldestFirst': 'Mas antiguo primero',
  'admin.listView.results.error.cannotDeleteSameAccount': 'No puede eliminar su propia cuenta',
  'admin.listView.results.search.placeholder': 'Buscar usuario',
  'admin.listView.results.search.button': 'Buscar',
  'admin.listView.results.delete.button': 'Borrar',
  'admin.listView.results.table.column.name': 'Nombre',
  'admin.listView.results.table.column.role': 'Rol',
  'admin.listView.results.table.column.actions': 'Acciones',
  'admin.listView.results.table.footer.rowsPerPage': 'Filas por página',
  'admin.listView.results.table.noData': 'No hay datos',
  'admin.upsert.page.title.createUser': 'Crear Usuario',
  'admin.upsert.page.title.editUser': 'Editar Usuario',
  'admin.upsert.header.title.createUser': 'Crear usuario',
  'admin.upsert.header.title.editUser': 'Editar usuario',
  'admin.upsert.header.cancel.button': 'Cancelar',
  'admin.upsert.form.validation.enterName': 'Debe ingresar un nombre',
  'admin.upsert.form.validation.enterLastname': 'Debe ingresar un apellido',
  'admin.upsert.form.validation.enterRole': 'Debe ingresar un rol',
  'admin.upsert.form.error.goBackToUsers.actionButton': 'Volver a usuarios',
  'admin.upsert.form.textField.name': 'Nombre',
  'admin.upsert.form.textField.lastname': 'Apellido',
  'admin.upsert.form.textField.email': 'Correo',
  'admin.upsert.form.textField.role': 'Rol',
  'admin.upsert.form.textField.role.option.admin': 'ADMIN',
  'admin.upsert.form.textField.role.option.user': 'USER',
  'admin.upsert.form.textField.state': 'Estado',
  'admin.upsert.form.textField.state.option.active': 'ACTIVO',
  'admin.upsert.form.textField.state.option.inactive': 'INACTIVO',
  'admin.upsert.form.card.save.button': 'Guardar',
  'navigation.navbar.admin.header.title': 'Administración',
  'navigation.navbar.admin.subheader.title.users': 'Usuarios',
  'navigation.topbar.account.edit': 'Manejar información perfil',
  'navigation.topbar.account.closeSession': 'Cerrar Sesión',
  'navigation.topbar.settings.title': 'Ajustes',
  'navigation.topbar.settings.theme.title': 'Tema',
  'navigation.topbar.settings.language.title': 'Idioma',
  'navigation.topbar.settings.language.option.english': 'Inglés',
  'navigation.topbar.settings.language.option.spanish': 'Español',
  'navigation.topbar.settings.save.button': 'Guardar',
  'noAuthorization.title': 'Sin autorizacion',
  'noAuthorization.firstMessage': '¡Ups! No tienes permiso para acceder esta página. Por favor pide permiso al administrador de tu cuenta, clickea ',
  'noAuthorization.here': 'aquí',
  'noAuthorization.secondMessage': ' para ir a tu página principal, o clickea ',
  'noAuthorization.thirdMessage': ' para cambiar de usuario.',
  // API ERRORS
  [NO_TOKEN]: NO_TOKEN,
  [INVALID_TOKEN]: INVALID_TOKEN,
  [INVALID_ROLE]: INVALID_ROLE,
  [REQUIRED_TOKEN]: REQUIRED_TOKEN,
  [EXPIRED_LINK]: EXPIRED_LINK,
  [PROHIBITTED]: PROHIBITTED,
  [USED_INVALID_TOKEN]: USED_INVALID_TOKEN,
  [USER_INACTIVE]: USER_INACTIVE,
  [USER_DOES_NOT_EXIST]: USER_DOES_NOT_EXIST,
  [WRONG_CREDENTIALS]: WRONG_CREDENTIALS,
  [PASSWORDS_DO_NOT_MATCH]: PASSWORDS_DO_NOT_MATCH,
  [TOKEN_NOT_GENERATED]: TOKEN_NOT_GENERATED,
  [REQUIRED_EMAIL]: REQUIRED_EMAIL,
  [USER_NOT_REGISTERED]: USER_NOT_REGISTERED,
  [VERIFICATION_MAIL_ERROR]: VERIFICATION_MAIL_ERROR,
  [GMAIL_USER_NOT_FOUND]: GMAIL_USER_NOT_FOUND,
  [GMAIL_CONNECTION_ERROR]: GMAIL_CONNECTION_ERROR,
  [USER_ALREADY_EXIST]: USER_ALREADY_EXIST,
  [INVALID_ARGUMENT]: INVALID_ARGUMENT,
  [NO_USERS_FOUND]: NO_USERS_FOUND,
  [DEFAULT_API_ERROR_MESSAGE]: DEFAULT_API_ERROR_MESSAGE,
  // API SUCCESS
  [USER_VERIFIED]: USER_VERIFIED,
  [SUCCESSFUL_LOGIN]: SUCCESSFUL_LOGIN,
  [RECOVER_PASSWORD_MAIL_SENT]: RECOVER_PASSWORD_MAIL_SENT,
  [PASSWORD_UPDATED]: PASSWORD_UPDATED,
  [USER_CREATED]: USER_CREATED,
  [USERS_FOUND]: USERS_FOUND,
  [USER_EDITED]: USER_EDITED,
  [USER_FOUND]: USER_FOUND,
  [USERS_DELETED]: USERS_DELETED,
};
