import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Icon,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';

import { useDispatch, useSelector } from 'react-redux';
import { MonetizationOn } from '@mui/icons-material';
import useStyles from './styles';
import { ReduxState } from '../../../types';
import { getHoldingsResumeByUserId } from '../../../requests/api/holdings';
import handleApiResponse from '../../../utils/handleApiResponse';
import { currencyFormatter, fixedFormatOnZero } from '../../../utils/usefulFormatters';
import { setBalance } from '../../../actions/dashboardActions';

function Balance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: ReduxState) => state.account.user);

  const dashBoardState = useSelector((state: ReduxState) => state.dashboard);

  useEffect(() => {
    const holdingsResumeByUserId = async (userId: number) => {
      try {
        const response = await getHoldingsResumeByUserId([userId]);
        dispatch(setBalance(response.holdingsResume[userId]));
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (user) {
      holdingsResumeByUserId(user.id);
    }
  }, [dashBoardState.newTransaction, user?.hasStarted, dashBoardState.lastUpdatedDatePrice]);

  return (
    <Box>
      <Box className={classes.titleBalanceBox}>
        <Icon className={classes.titleIcon}>
          <MonetizationOn />
        </Icon>
        <Typography
          className={classes.sectionTitle}
        >
          My balance
        </Typography>
      </Box>
      <Card
        className={classes.cardComponent}
        elevation={4}
      >
        <CardContent
          className={classes.cardContent}
        >
          <Box className={classes.individualCard}>
            <Typography
              className={classes.balanceHeaderTitle}
            >
              Invested
            </Typography>
            <Typography
              className={classes.numberTitle}
            >

              {currencyFormatter(dashBoardState.balance?.portfolioBalance ?? 0)}
            </Typography>
          </Box>
          <Divider orientation='vertical' />
          <Box className={classes.individualCard}>
            <Typography
              className={classes.balanceHeaderTitle}
            >
              Cash
            </Typography>
            <Typography
              className={classes.numberTitle}
            >
              {currencyFormatter(dashBoardState.balance?.cashBalance ?? 0)}
            </Typography>
          </Box>
          <Divider orientation='vertical' />
          <Box className={classes.individualCard}>
            <Typography
              className={classes.balanceHeaderTitle}
            >
              Portfolio
            </Typography>
            <Typography
              className={classes.numberTitle}
            >
              {currencyFormatter((dashBoardState.balance?.cashBalance ?? 0)
                + (dashBoardState.balance?.portfolioBalance ?? 0))}
            </Typography>
          </Box>
          <Divider orientation='vertical' />
          <Box className={classes.individualCard}>
            <Typography
              className={classes.balanceHeaderTitle}
            >
              Performance
            </Typography>
            <Typography
              className={classes.numberTitle}
            >
              {`${fixedFormatOnZero((dashBoardState?.leaders
                .filter((item) => item.id === user?.id))[0]?.percentage * 100 || 0, 2)} %`
              }
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>

  );
}

Balance.propTypes = {
  className: PropTypes.string,
};

export default Balance;
