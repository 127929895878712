import React, {
  forwardRef,
  useEffect,
  useCallback,
  ForwardRefRenderFunction,
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import track from '../../utils/analytics';
import { PageProps } from '../../types';

const Page: ForwardRefRenderFunction<HTMLDivElement, PageProps> = (
  { title, children, ...rest },
  ref,
) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default forwardRef(Page);
