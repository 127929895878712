import { Dispatch, useEffect } from 'react';
import socket from '../requests/api/socket';
import { logout } from '../actions/accountActions';
import { persistorFlush } from '../store';
import { newPriceNotifier, resetDashboard } from '../actions/dashboardActions';

const useSocket = (dispatch: Dispatch<any>) => {
  const logoutSession = async () => {
    dispatch(logout());
    dispatch(resetDashboard());
    await persistorFlush();
  };

  useEffect(() => {
    socket.connect();
    socket.on('updatedPrices', (data: any) => {
      dispatch(newPriceNotifier(data));
    });
    socket.on('connect error', () => {
      logoutSession();
    });
    return () => {
      socket.off('connect error');
    };
  }, []);
};

export default useSocket;
