import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';

import UserBalance from './views/Dashboard/index';
import { ChildrenProps, RouteConfig } from './types';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    id: 10,
    path: '/dashboard',
    guard: (props: ChildrenProps) => <AuthGuard {...props} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/dashboard',
        component: () => <Redirect to="/dashboard/balance" />,
      },
      {
        id: 12,
        exact: true,
        path: '/dashboard/balance',
        component: () => <UserBalance />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: RouteConfig, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component as React.ElementType;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
