declare global {
  interface Window {
    gtag: (...args: string[]) => void;
  }
}

function track(...args: string[]) {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!window.gtag) {
    return;
  }

  window.gtag(...args);
}

const pageview = (props: object) => {
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
  if (REACT_APP_GA_MEASUREMENT_ID) track('config', REACT_APP_GA_MEASUREMENT_ID, JSON.stringify(props));
};

const event = (type: string, props: string) => {
  track('event', type, props);
};

const trackObj = {
  pageview,
  event,
};

export default trackObj;
