import English from './en';
import Spanish from './es';

const supportedLanguages = ['en', 'es'] as const;

export type LanguageCode = typeof supportedLanguages[number];

const localeDef = {
  en: 'en-US',
  es: 'es-ES',
} as const;

export default { en: English, es: Spanish, def: { ...localeDef } } as const;
