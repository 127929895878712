import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  loginAndPhotoContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  loginContainer: {
    display: 'flex',
    width: '41%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 15px',
  },
  welcomeTitle: {
    fontSize: '58px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('xl')]: {
      textAlign: 'center',
      whiteSpace: 'normal',
    },
  },
  photoContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  backgroundImageContainer: {
    margin: 'auto',
    height: '95%',
    width: '97%',
    position: 'relative',
  },
  logoImageTil: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '45%',
    maxWidth: '40%',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '53%',
    },
  },
  logoImageLegend: {
    position: 'absolute',
    top: '85%',
    left: '53%',
    maxHeight: '16%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '33%',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '42%',
      top: '83%',
    },
  },
  signInButton: {
    borderRadius: '100px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '350px',
  },
}));

export default useStyles;
