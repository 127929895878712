import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
} from '@mui/material';
import Page from '../../components/Layout/Page';
import LoginForm from './LoginForm';
import AuthService from '../../services/authService';
import useStyles from './styles';
import { ReduxState } from '../../types';
import LogoLegend from '../../components/Layout/LogoLegend';

function LoginView() {
  const classes = useStyles();
  const history = useHistory();
  const account = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    if (account?.user && AuthService.isAuthenticated()) {
      history.push('/dashboard');
    }
  }, [account.user]);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box className={classes.loginAndPhotoContainer}>
        <Box className={classes.loginContainer}>
          <Typography className={classes.welcomeTitle} >
            Welcome to TIL!
          </Typography>
          <Box mt={3}>
            <LoginForm />
          </Box>
        </Box>
        <Box className={classes.photoContainer}>
          <Box
          className={classes.backgroundImageContainer}
          >
            <img
              // eslint-disable-next-line global-require
              src={require('../../assets/images/background-login-logo-purple.jpg')}
              className={classes.backgroundImage}
            />
          </Box>
          <img
            alt="Logo"
            // eslint-disable-next-line global-require
            src={require('../../assets/images/logo.png')}
            className={classes.logoImageTil}
          />
          <img
            alt="Logo-legend"
            // eslint-disable-next-line global-require
            src={require('../../assets/images/logo-legend.png')}
            className={classes.logoImageLegend}
          />
        </Box>
      </Box>
    </Page>
  );
}

export default LoginView;
