/* eslint no-param-reassign: ["error", { "props": false }] */
import produce, { Draft } from 'immer';
import {
  SET_BALANCE,
  NEW_TRANSACTION_NOTIFIER,
  SET_SELECTED_USER_TO_GRAPH,
  NEW_PRICE_NOTIFIER,
  RESET_DASHBOARD,
  SET_LEADERS,
} from '../actions/dashboardActions';
import { CustomAction, DashboardState, Leaders } from '../types';

const initialState: DashboardState = {
  balance: {
    cashBalance: 0,
    portfolioBalance: 0,
  },
  newTransaction: 0,
  lastUpdatedDatePrice: Date.now(),
  userToGraph: [],
  leaders: [],
};

const dashboardReducer = (state = initialState, action: CustomAction): DashboardState => {
  switch (action.type) {
    case SET_BALANCE: {
      const { cashBalance, portfolioBalance } = action.payload;

      return produce(state, (draft: Draft<DashboardState>) => {
        draft.balance = { cashBalance, portfolioBalance };
      });
    }

    case NEW_PRICE_NOTIFIER: {
      const dateUpdated = action.payload;

      return produce(state, (draft: Draft<DashboardState>) => {
        draft.lastUpdatedDatePrice = dateUpdated as unknown as number;
      });
    }

    case SET_SELECTED_USER_TO_GRAPH: {
      const arrayToGraph = action.payload;

      return produce(state, (draft: Draft<DashboardState>) => {
        draft.userToGraph = arrayToGraph as unknown as number[];
      });
    }

    case NEW_TRANSACTION_NOTIFIER: {
      return produce(state, (draft: Draft<DashboardState>) => {
        draft.newTransaction = state.newTransaction + 1;
      });
    }

    case RESET_DASHBOARD: {
      return produce(state, (draft: Draft<DashboardState>) => {
        draft.newTransaction = 0;
        draft.lastUpdatedDatePrice = Date.now();
        draft.userToGraph = [];
        draft.balance = {
          cashBalance: 0,
          portfolioBalance: 0,
        };
        draft.leaders = [];
      });
    }

    case SET_LEADERS: {
      const allLeaders = action.payload;

      return produce(state, (draft: Draft<DashboardState>) => {
        draft.leaders = allLeaders as unknown as Leaders[];
      });
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
