/* eslint-disable no-console */
import _ from 'lodash';
import {
  createTheme, responsiveFontSizes, Shadows, ThemeOptions,
} from '@mui/material';
import typography from './typography';
import { strongShadows } from './shadows';
import { THEMES } from '../constants';
import { ContextSettings } from '../types';

declare module '@mui/material/styles' {
  interface Theme {
    name: string;
    logo: {
      filter: string;
      filterLoad: string;
    };
    settings: {
      filter: string;
    };
  }

  interface ThemeOptions {
    name?: string;
    overrides?: object;
    logo?: {
      filter?: string;
      filterLoad?: string;
    };
    settings?: {
      filter?: string;
    };
  }

  interface PaletteColorOptions {
    white?: string;
    main?: string;
    button?: string;
  }

  interface TypeBackground {
    default: string;
    dark?: string;
    paper: string;
    transition?: string;
    component: string,
  }

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary?: string;
  }

  interface PaletteColor {
    white?: string;
  }
}

const baseConfig: ThemeOptions = {
  direction: 'ltr',
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: 900,
      xl: 1280,
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themeConfigs: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              background: '#BDBDBD',
              color: 'white',
            },
          },
        },
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#7738ed',
          },
        },
      },

    },
    palette: {
      mode: 'light',
      action: {
        active: '#7738ed',
      },
      background: {
        default: '#7738ed',
        paper: '#FFFFFF',
        component: '#F6F5FF',
      },
      primary: {
        main: '#7738ed',
        white: '#FFFFFF',
      },
      secondary: {
        main: '#22335E',
      },
      text: {
        primary: '#000005',
        secondary: '#7738ed',
        tertiary: 'white',
      },
    },

    settings: {
      filter: '',
    },
    shadows: strongShadows as Shadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
        transition: '#ffffff',
      },
      primary: {
        main: '#002D3B',
        white: '#FFFFFF',
      },
      secondary: {
        button: '#003E6B',
        main: '#e6e5e8',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    logo: {
      filter: '',
      filterLoad: '',
    },
    settings: {
      filter: 'invert(100%)',
    },
    shadows: strongShadows as Shadows,
  },
];

export function createCustomTheme(settings: ContextSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  lang: 'en',
}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction },
    ),
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
