import { EditUserData } from '../types';
import axios from '../../utils/axios';

const getUserDetail = async (id: number | string) => {
  const response = await axios.get(`/api/users/${id}`);

  return response.data;
};

const restartRace = async (userAdmin: string) => {
  const response = await axios.delete(`/api/users?username=${userAdmin}`);

  return response.data;
};

const getUserById = async (userId: number | string) => {
  const response = await axios.get(`/api/users/${userId}`);

  return response.data;
};

const getUsersFilteredScoreboard = async ({
  sort,
  isFriendFiltered,
  isCountryFiltered,
}: Record<string, any>) => {
  const response = await axios.get(`/api/users-filters?sort=${sort}&friendsFilter=${isFriendFiltered}&countryFilter=${isCountryFiltered}`);

  return response.data;
};

const getUsersScoreboard = async ({ sort }: Record<string, string>) => {
  const response = await axios.get(`/api/users?sort=${sort}`);

  return response.data;
};

const getFriendsFromUser = async () => {
  const response = await axios.get('/api/user-friends');

  return response.data;
};

const postNewFriends = async (id: number) => {
  const response = await axios.post('/api/user-friends', { id });

  return response.data;
};

const putUserStartRace = async ({
  id,
}: { [key: string]: number }) => {
  const response = await axios.put('/api/user-start-race', { id });

  return response.data;
};

const putUser = async (userInfo: { id: number, nameTag: string, country: string }) => {
  const response = await axios.put('/api/user', { userInfo });

  return response.data;
};

export {
  getUserDetail,
  restartRace,
  getUserById,
  getUsersFilteredScoreboard,
  getUsersScoreboard,
  getFriendsFromUser,
  postNewFriends,
  putUserStartRace,
  putUser,
};
