import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import {
  Card,
  CardContent,
  Box,
  Modal,
  IconButton,
  Icon,
  Typography,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Calendar } from 'react-feather';
import moment from 'moment';
import useStyles from './styles';
import { ReduxState, TransactionDetailResponse } from '../../../types';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getHistoricalDetailedHoldingsFromUsers, getHistoricalHoldingsFromUsers, getHoldingsResumeByUserId } from '../../../requests/api/holdings';
import {
  calculateNextInterval,
  currencyFormatter,
  dateFormatter,
  decimalsRound,
  twoDatesDiff,
  getInitialDatePassingUTC,
} from '../../../utils/usefulFormatters';

function BalanceChart(this: any, props: HighchartsReact.Props) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const classes = useStyles();
  const user = useSelector((state: ReduxState) => state.account.user);
  const dashboardState = useSelector((state: ReduxState) => state.dashboard);
  const [xAxis, setXAxis] = useState<Record<string, any>>({
    dateValues: [],
    source: '',
  });
  const [xValues, setXValues] = useState<Record<string, any>>({});
  const [zoomOut, setZoomOut] = useState(true);
  const [openDetailedModal, setOpenDetailedModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Record<string, any>>({});
  const [allHoldings, setAllHoldings] = useState<Record<string, any>>({});
  const [selectedDetail, setSelectedDetail] = useState<TransactionDetailResponse>({
    dayHoldings: {},
    prices: {},
  });
  const { enqueueSnackbar } = useSnackbar();

  exporting(Highcharts);

  const handleSeeMore = async (event: any) => {
    try {
      const relUser = event.point.series.name;
      const relDate = Object.keys(allHoldings[relUser])[event.point.x];

      if (relUser) {
        setSelectedDay(event);
        setSelectedDetail(allHoldings[relUser][relDate]);
        setOpenDetailedModal(true);
      } else {
        throw new Error('Can\'t get user information');
      }
    } catch (err) {
      const e = err as Error;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleOnCloseModal = () => {
    setSelectedDay({});
    setSelectedDetail({
      dayHoldings: {},
      prices: {},
    });
    setOpenDetailedModal(false);
  };

  useEffect(() => {
    const getHistoricalDailyHoldings = async () => {
      const selectedUsers = dashboardState.userToGraph;
      const usersStartingToday: string[] = [];
      let auxXAxis: string[] = [];
      let auxXValues: any = {};
      let auxHoldingsPerDay: Record<string, any> = {};
      const todayDate = `${Date.now()}`;
      try {
        const { holdingsResume } = await getHoldingsResumeByUserId(selectedUsers);

        // checks if every user has today's starting date
        Object.values(holdingsResume).forEach((item: any) => {
          const auxIniDate = new Date(item.startingDate);
          const auxTodayDate = new Date(Date.now());
          const iniDate = new Date(
            auxIniDate.getFullYear(), auxIniDate.getMonth(), auxIniDate.getDate(),
          ).getTime();
          const today = new Date(
            auxTodayDate.getFullYear(), auxTodayDate.getMonth(), auxTodayDate.getDate(),
          ).getTime();

          if (iniDate === today) usersStartingToday.push(item.username);
        });

        if (usersStartingToday.length === selectedUsers.length) {
          Object.values(holdingsResume).forEach((relItem: any) => {
            auxHoldingsPerDay = {
              ...auxHoldingsPerDay,
              [relItem.username]: {
                [todayDate]: {
                  dayHoldings: relItem.holdings || {},
                  prices: relItem.prices || {},
                },
              },
            };

            auxXAxis = [todayDate];
            auxXValues = {
              ...auxXValues,
              [relItem.username]: {
                portfolioBalance: [relItem.portfolioBalance],
                cashBalance: [relItem.cashBalance],
                totalBalance: [{
                  y: relItem.portfolioBalance + relItem.cashBalance,
                  marker: {
                    symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0zMjAtMjQwaDYwdi0yMDBoMTAwbDQwIDgwaDIwMHYtMjQwSDYwMGwtNDAtODBIMzIwdjQ0MFptMjM3LTE4MC00MC04MEgzODB2LTEyMGgxNDNsNDAgODBoOTd2MTIwSDU1N1pNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFptMC04MHExMzQgMCAyMjctOTN0OTMtMjI3cTAtMTM0LTkzLTIyN3QtMjI3LTkzcS0xMzQgMC0yMjcgOTN0LTkzIDIyN3EwIDEzNCA5MyAyMjd0MjI3IDkzWm0wLTMyMFoiLz48L3N2Zz4=)',
                  },
                  accessibility: {
                    description: 'Starting point of user',
                  },
                }],
              },
            };
          });
        } else {
          const { selectedUsersHoldingHistory } = await getHistoricalHoldingsFromUsers(
            'daily',
            selectedUsers,
          );

          Object.values(selectedUsersHoldingHistory).forEach((relItem: any, index) => {
            auxHoldingsPerDay = {
              ...auxHoldingsPerDay,
              [relItem.username]: Object.entries(relItem)
                .reduce((acc: Record<string, any>, val: any) => {
                  if (!Number.isNaN(Number(val[0]))) {
                    return {
                      ...acc,
                      [val[0]]: {
                        dayHoldings: val[1].dayHoldings || {},
                        prices: relItem.prices[val[0]] || {},
                      },
                    };
                  }
                  return acc;
                }, {}),
            };

            const originalValidX = Object.keys(relItem)
              .filter((itm) => !Number.isNaN(Number(itm)));

            const validX = originalValidX
              .map((itm) => `${getInitialDatePassingUTC(itm)}`);

            // Relevant userkey id
            const relevantKeyId = Object.keys(selectedUsersHoldingHistory)[index];
            const startingDate = new Date(selectedUsersHoldingHistory[relevantKeyId].startingDate);
            const initialDate = new Date(
              startingDate.getFullYear(),
              startingDate.getMonth(),
              startingDate.getDate(),
            );

            auxXAxis = [...new Set(validX), todayDate];
            auxXValues = {
              ...auxXValues,
              [relItem.username]: {
                portfolioBalance: originalValidX.map((itm) => {
                  if (initialDate.getTime() <= getInitialDatePassingUTC(itm)) {
                    return relItem[itm].portfolioBalance;
                  }
                  return null;
                }),
                cashBalance: originalValidX.map((itm) => {
                  if (initialDate.getTime() <= getInitialDatePassingUTC(itm)) {
                    return relItem[itm].cashBalance;
                  }
                  return null;
                }),
                totalBalance: originalValidX.map((itm) => {
                  if (initialDate.getTime() < getInitialDatePassingUTC(itm)) {
                    if (relItem[itm].hasTraded) {
                      return {
                        y: relItem[itm].portfolioBalance + relItem[itm].cashBalance,
                        marker: {
                          symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik00NDQtMjAwaDcwdi01MHE1MC05IDg2LTM5dDM2LTg5cTAtNDItMjQtNzd0LTk2LTYxcS02MC0yMC04My0zNXQtMjMtNDFxMC0yNiAxOC41LTQxdDUzLjUtMTVxMzIgMCA1MCAxNS41dDI2IDM4LjVsNjQtMjZxLTExLTM1LTQwLjUtNjFUNTE2LTcxMHYtNTBoLTcwdjUwcS01MCAxMS03OCA0NHQtMjggNzRxMCA0NyAyNy41IDc2dDg2LjUgNTBxNjMgMjMgODcuNSA0MXQyNC41IDQ3cTAgMzMtMjMuNSA0OC41VDQ4Ni0zMTRxLTMzIDAtNTguNS0yMC41VDM5MC0zOTZsLTY2IDI2cTE0IDQ4IDQzLjUgNzcuNVQ0NDQtMjUydjUyWm0zNiAxMjBxLTgzIDAtMTU2LTMxLjVUMTk3LTE5N3EtNTQtNTQtODUuNS0xMjdUODAtNDgwcTAtODMgMzEuNS0xNTZUMTk3LTc2M3E1NC01NCAxMjctODUuNVQ0ODAtODgwcTgzIDAgMTU2IDMxLjVUNzYzLTc2M3E1NCA1NCA4NS41IDEyN1Q4ODAtNDgwcTAgODMtMzEuNSAxNTZUNzYzLTE5N3EtNTQgNTQtMTI3IDg1LjVUNDgwLTgwWm0wLTgwcTEzNCAwIDIyNy05M3Q5My0yMjdxMC0xMzQtOTMtMjI3dC0yMjctOTNxLTEzNCAwLTIyNyA5M3QtOTMgMjI3cTAgMTM0IDkzIDIyN3QyMjcgOTNabTAtMzIwWiIvPjwvc3ZnPg==)',
                        },
                        accessibility: {
                          description: 'User traded',
                        },
                      };
                    }
                    return relItem[itm].portfolioBalance + relItem[itm].cashBalance;
                  }
                  if (initialDate.getTime() === getInitialDatePassingUTC(itm)) {
                    return {
                      y: relItem[itm].portfolioBalance + relItem[itm].cashBalance,
                      marker: {
                        symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0zMjAtMjQwaDYwdi0yMDBoMTAwbDQwIDgwaDIwMHYtMjQwSDYwMGwtNDAtODBIMzIwdjQ0MFptMjM3LTE4MC00MC04MEgzODB2LTEyMGgxNDNsNDAgODBoOTd2MTIwSDU1N1pNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFptMC04MHExMzQgMCAyMjctOTN0OTMtMjI3cTAtMTM0LTkzLTIyN3QtMjI3LTkzcS0xMzQgMC0yMjcgOTN0LTkzIDIyN3EwIDEzNCA5MyAyMjd0MjI3IDkzWm0wLTMyMFoiLz48L3N2Zz4=)',
                      },
                      accessibility: {
                        description: 'Starting point of user',
                      },
                    };
                  }
                  return null;
                }),
              },
            };

            const auxActualTotalBalance = usersStartingToday.includes(relItem.username)
              ? {
                y: holdingsResume[relevantKeyId].portfolioBalance
                  + holdingsResume[relevantKeyId].cashBalance,
                marker: {
                  symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0zMjAtMjQwaDYwdi0yMDBoMTAwbDQwIDgwaDIwMHYtMjQwSDYwMGwtNDAtODBIMzIwdjQ0MFptMjM3LTE4MC00MC04MEgzODB2LTEyMGgxNDNsNDAgODBoOTd2MTIwSDU1N1pNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFptMC04MHExMzQgMCAyMjctOTN0OTMtMjI3cTAtMTM0LTkzLTIyN3QtMjI3LTkzcS0xMzQgMC0yMjcgOTN0LTkzIDIyN3EwIDEzNCA5MyAyMjd0MjI3IDkzWm0wLTMyMFoiLz48L3N2Zz4=)',
                },
                accessibility: {
                  description: 'Starting point of user',
                },
              }
              : holdingsResume[relevantKeyId].portfolioBalance
              + holdingsResume[relevantKeyId].cashBalance;

            auxXValues[relItem.username].portfolioBalance
              .push(holdingsResume[relevantKeyId].portfolioBalance);
            auxXValues[relItem.username].cashBalance
              .push(holdingsResume[relevantKeyId].cashBalance);
            auxXValues[relItem.username].totalBalance
              .push(auxActualTotalBalance);
            auxHoldingsPerDay[relItem.username][todayDate] = {
              dayHoldings: holdingsResume[relevantKeyId].holdings,
              prices: holdingsResume[relevantKeyId].prices,
            };
          });
        }
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
      setXAxis({ dateValues: auxXAxis, source: 'day' });
      setXValues(auxXValues);
      setAllHoldings(auxHoldingsPerDay);
    };

    const getDetailedHolding = async () => {
      const selectedUsers = dashboardState.userToGraph;
      const usersStartingToday: string[] = [];
      let auxXAxis: string[] = [];
      let auxXValues: any = {};
      let auxHoldingsPerInterval: Record<string, any> = {};
      const auxTodayDate = new Date(Date.now());
      const today = calculateNextInterval(moment(auxTodayDate), 30).format('YYYY-MM-DD HH:mm');
      try {
        const { holdingsResume } = await getHoldingsResumeByUserId(selectedUsers);
        // checks if every user has today's starting date
        Object.values(holdingsResume).forEach((item: any) => {
          const auxIniDate = new Date(item.startingDate);
          const iniDate = new Date(
            auxIniDate.getFullYear(),
            auxIniDate.getMonth(),
            auxIniDate.getDate(),
          ).getTime();
          const todayDateTime = new Date(
            auxTodayDate.getFullYear(),
            auxTodayDate.getMonth(),
            auxTodayDate.getDate(),
          ).getTime();

          if (iniDate === todayDateTime) usersStartingToday.push(item.username);
        });

        if (usersStartingToday.length === selectedUsers.length) {
          Object.values(holdingsResume).forEach((relItem: any) => {
            auxHoldingsPerInterval = {
              ...auxHoldingsPerInterval,
              [relItem.username]: {
                [today]: {
                  dayHoldings: relItem.dayHoldings || {},
                  prices: relItem.prices,
                },
              },
            };
            auxXAxis = [today];
            auxXValues = {
              ...auxXValues,
              [relItem.username]: {
                portfolioBalance: [relItem.portfolioBalance],
                cashBalance: [relItem.cashBalance],
                totalBalance: [{
                  y: relItem.portfolioBalance + relItem.cashBalance,
                  marker: {
                    symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0zMjAtMjQwaDYwdi0yMDBoMTAwbDQwIDgwaDIwMHYtMjQwSDYwMGwtNDAtODBIMzIwdjQ0MFptMjM3LTE4MC00MC04MEgzODB2LTEyMGgxNDNsNDAgODBoOTd2MTIwSDU1N1pNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFptMC04MHExMzQgMCAyMjctOTN0OTMtMjI3cTAtMTM0LTkzLTIyN3QtMjI3LTkzcS0xMzQgMC0yMjcgOTN0LTkzIDIyN3EwIDEzNCA5MyAyMjd0MjI3IDkzWm0wLTMyMFoiLz48L3N2Zz4=)',
                  },
                  accessibility: {
                    description: 'Starting point of user',
                  },
                }],
              },
            };
          });
        } else {
          const {
            selectedUsersHoldingHistory,
          } = await getHistoricalDetailedHoldingsFromUsers('interval', selectedUsers);
          Object.values(selectedUsersHoldingHistory).forEach((relItem: any, index: number) => {
            auxHoldingsPerInterval = {
              ...auxHoldingsPerInterval,
              [relItem.username]: Object
                .entries(relItem)
                .reduce((acc: Record<string, any>, val: any) => {
                  if (!Number.isNaN(Number(val[0]))) {
                    const indexFormatted = moment(new Date(Number(val[0]))).format('YYYY-MM-DD HH:mm');
                    return {
                      ...acc,
                      [indexFormatted]: {
                        dayHoldings: val[1].dayHoldings || {},
                        prices: relItem.prices[val[0]] || {},
                      },
                    };
                  }
                  return acc;
                }, {}),
            };

            const relevantKeyId = Object.keys(selectedUsersHoldingHistory)[index];
            const startingDate = moment(selectedUsersHoldingHistory[relevantKeyId].startingDate);
            const initialDate = new Date(calculateNextInterval(startingDate, 30).format('YYYY-MM-DD HH:mm:ss'));

            const validX = Object.keys(relItem)
              .filter((itm) => !Number.isNaN(new Date(Number(itm)).valueOf()))
              .map((itm) => moment(new Date(Number(itm))).format('YYYY-MM-DD HH:mm'));

            auxXAxis = [...new Set(validX)];

            auxXValues = {
              ...auxXValues,
              [relItem.username]: {
                portfolioBalance: validX.map((itm) => {
                  const aux = new Date(moment(itm).toISOString()).getTime();
                  if (initialDate.getTime() <= aux) {
                    return relItem[aux].portfolioBalance;
                  }
                  return null;
                }),
                cashBalance: validX.map((itm) => {
                  const aux = new Date(moment(itm).toISOString()).getTime();
                  if (initialDate.getTime() <= aux) {
                    return relItem[aux].cashBalance;
                  }
                  return null;
                }),
                totalBalance: validX.map((itm) => {
                  const aux = new Date(moment(itm).toISOString()).getTime();
                  if (initialDate.getTime() < aux) {
                    if (relItem[aux].hasTraded) {
                      return {
                        y: relItem[aux].portfolioBalance + relItem[aux].cashBalance,
                        marker: {
                          symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik00NDQtMjAwaDcwdi01MHE1MC05IDg2LTM5dDM2LTg5cTAtNDItMjQtNzd0LTk2LTYxcS02MC0yMC04My0zNXQtMjMtNDFxMC0yNiAxOC41LTQxdDUzLjUtMTVxMzIgMCA1MCAxNS41dDI2IDM4LjVsNjQtMjZxLTExLTM1LTQwLjUtNjFUNTE2LTcxMHYtNTBoLTcwdjUwcS01MCAxMS03OCA0NHQtMjggNzRxMCA0NyAyNy41IDc2dDg2LjUgNTBxNjMgMjMgODcuNSA0MXQyNC41IDQ3cTAgMzMtMjMuNSA0OC41VDQ4Ni0zMTRxLTMzIDAtNTguNS0yMC41VDM5MC0zOTZsLTY2IDI2cTE0IDQ4IDQzLjUgNzcuNVQ0NDQtMjUydjUyWm0zNiAxMjBxLTgzIDAtMTU2LTMxLjVUMTk3LTE5N3EtNTQtNTQtODUuNS0xMjdUODAtNDgwcTAtODMgMzEuNS0xNTZUMTk3LTc2M3E1NC01NCAxMjctODUuNVQ0ODAtODgwcTgzIDAgMTU2IDMxLjVUNzYzLTc2M3E1NCA1NCA4NS41IDEyN1Q4ODAtNDgwcTAgODMtMzEuNSAxNTZUNzYzLTE5N3EtNTQgNTQtMTI3IDg1LjVUNDgwLTgwWm0wLTgwcTEzNCAwIDIyNy05M3Q5My0yMjdxMC0xMzQtOTMtMjI3dC0yMjctOTNxLTEzNCAwLTIyNyA5M3QtOTMgMjI3cTAgMTM0IDkzIDIyN3QyMjcgOTNabTAtMzIwWiIvPjwvc3ZnPg==)',
                        },
                        accessibility: {
                          description: 'User traded',
                        },
                      };
                    }
                    return relItem[aux].portfolioBalance + relItem[aux].cashBalance;
                  }
                  if (initialDate.getTime() === aux) {
                    return {
                      y: relItem[aux].portfolioBalance + relItem[aux].cashBalance,
                      marker: {
                        symbol: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Ik0zMjAtMjQwaDYwdi0yMDBoMTAwbDQwIDgwaDIwMHYtMjQwSDYwMGwtNDAtODBIMzIwdjQ0MFptMjM3LTE4MC00MC04MEgzODB2LTEyMGgxNDNsNDAgODBoOTd2MTIwSDU1N1pNNDgwLTgwcS04MyAwLTE1Ni0zMS41VDE5Ny0xOTdxLTU0LTU0LTg1LjUtMTI3VDgwLTQ4MHEwLTgzIDMxLjUtMTU2VDE5Ny03NjNxNTQtNTQgMTI3LTg1LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgzLTMxLjUgMTU2VDc2My0xOTdxLTU0IDU0LTEyNyA4NS41VDQ4MC04MFptMC04MHExMzQgMCAyMjctOTN0OTMtMjI3cTAtMTM0LTkzLTIyN3QtMjI3LTkzcS0xMzQgMC0yMjcgOTN0LTkzIDIyN3EwIDEzNCA5MyAyMjd0MjI3IDkzWm0wLTMyMFoiLz48L3N2Zz4=)',
                      },
                      accessibility: {
                        description: 'Starting point of user',
                      },
                    };
                  }
                  return null;
                }),
              },
            };
            auxXAxis = [...auxXAxis, today];
            const auxActualTotalBalance = holdingsResume[relevantKeyId].cashBalance
              + holdingsResume[relevantKeyId].portfolioBalance;
            auxXValues[relItem.username].portfolioBalance
              .push(holdingsResume[relevantKeyId].portfolioBalance);
            auxXValues[relItem.username].cashBalance
              .push(holdingsResume[relevantKeyId].cashBalance);
            auxXValues[relItem.username].totalBalance
              .push(auxActualTotalBalance);
            auxHoldingsPerInterval[relItem.username][today] = {
              dayHoldings: holdingsResume[relevantKeyId].holdings,
              prices: holdingsResume[relevantKeyId].prices,
            };
          });
        }
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
      setAllHoldings(auxHoldingsPerInterval);
      setXAxis({ dateValues: auxXAxis, source: 'interval' });
      setXValues(auxXValues);
    };

    if (zoomOut && dashboardState.userToGraph.length > 0 && user?.hasStarted) {
      getHistoricalDailyHoldings();
    } else if (!zoomOut) {
      getDetailedHolding();
    }
  }, [dashboardState.userToGraph, user?.hasStarted, user?.username, user?.country, zoomOut]);

  useEffect(() => {
    const getLatestHoldings = async () => {
      const selectedUsers = dashboardState.userToGraph;
      try {
        const { holdingsResume } = await getHoldingsResumeByUserId(selectedUsers);
        const auxXValues = { ...xValues };

        Object.values(holdingsResume).forEach((relItem: any) => {
          auxXValues[relItem.username].portfolioBalance.pop();
          auxXValues[relItem.username].cashBalance.pop();
          auxXValues[relItem.username].totalBalance.pop();

          const auxTotalBalance = relItem.portfolioBalance
            + relItem.cashBalance;

          auxXValues[relItem.username].portfolioBalance
            .push(relItem.portfolioBalance);
          auxXValues[relItem.username].cashBalance
            .push(relItem.cashBalance);
          auxXValues[relItem.username].totalBalance.push(auxTotalBalance);
        });

        setXValues(auxXValues);
      } catch (err) {
        const e = err as Error;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (xAxis.dateValues.length > 0 && zoomOut) {
      getLatestHoldings();
    }
  }, [
    dashboardState.lastUpdatedDatePrice,
    dashboardState.newTransaction,
  ]);

  const options: Highcharts.Options = {
    chart: {
      type: 'spline',
      backgroundColor: '#F6F5FF',
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
        zoomOut: {
          text: 'zoomOut',
          onclick() {
            setZoomOut(true);
          },
        },
        zoomIn: {
          text: 'zoomIn',
          onclick() {
            setZoomOut(false);
          },
        },
      },
    },
    title: {
      style: { display: 'none' },
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
    },
    xAxis: {
      categories: [...xAxis.dateValues.map((itm: any) => dateFormatter(itm, xAxis.source))],
      accessibility: {
        description: 'Days of trading',
      },
      crosshair: true,
      lineWidth: 1,
    },
    yAxis: {
      lineWidth: 1,
      title: {
        style: { display: 'none' },
      },
      labels: {
        format: '{value}',
        formatter() {
          return `$ ${this.value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        }
        ,
      },
      crosshair: false,
      gridLineWidth: 0,
    },
    tooltip: {
      shared: false,
      useHTML: true,
      formatter() {
        return `
        <div>
        <div style='margin-bottom: 15px;'>
          <h4>User:</h4>
          <p style='color: #7738ED;'>
          ${this.series.name}
          </p>
        </div>
          <div style='margin-bottom: 15px;'>
            <h4>Invested:</h4>
            <p style='color: #7738ED;'>
            ${currencyFormatter(xValues[this.series.name]?.portfolioBalance[this.point.x])}
            </p>
          </div>
          <div style='margin-bottom: 15px;'>
            <h4>Cash:</h4>
            <p style='color: #7738ED;'>${currencyFormatter(xValues[this.series.name].cashBalance[this.point.x])}</p>
          </div>
          <div style='margin-bottom: 15px;'>
            <h4>Portfolio:</h4>
            <p style='color: #7738ED;'>
            ${typeof xValues[this.series.name].totalBalance[this.point.x] !== 'number'
    ? currencyFormatter(xValues[this.series.name].totalBalance[this.point.x].y)
    : currencyFormatter(xValues[this.series.name].totalBalance[this.point.x])}
            </p>
          </div>
      </div>`;
      },
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
    },
    series: Object.entries(xValues).map((item) => {
      const xArrayValues: { [key: string]: number[] } = item[1] as { [key: string]: number[] };

      return {
        name: item[0],
        type: 'spline',
        marker: {
          symbol: 'round',
        },
        allowPointSelect: true,
        data: [...xArrayValues.totalBalance],
        point: {
          events: {
            click(e) {
              handleSeeMore(e);
            },
          },
        },
      };
    }),
  };

  return (
    <Box
      className={classes.chartContainerBox}
    >
      <Card
        elevation={4}
        className={classes.chartBackgroundColor}
      >
        <CardContent className={classes.chartContentLeaderBoard}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
            containerProps={{
              style: {
                width: '99%',
              },
            }}
            {...props}
          />
        </CardContent>
      </Card>
      <Modal
        open={openDetailedModal}
        onClose={handleOnCloseModal}
      >
        <Card className={classes.modalCardWrapper}>
          <CardContent className={classes.modalCardContentWrapper}>
            <Box className={classes.modalHeader}>
              <Box className={classes.iconAndTitleBox}>
                <Icon>
                  <Calendar />
                </Icon>
                <Box className={classes.titleHeader}>
                  <Typography
                    variant='h1'
                  >
                    {`${selectedDay?.point?.series?.name} | ${selectedDay?.point?.category} | Day ${twoDatesDiff(
                      xAxis[selectedDay?.point?.series?.yData
                        ?.indexOf(selectedDay?.point?.series?.yData
                          .find((item: any) => item))],
                      xAxis[selectedDay?.point?.x],
                    )}`}
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={handleOnCloseModal} >
                X
              </IconButton>
            </Box>
            <Divider className={classes.modalDivider} />
            <Box className={classes.modalStockDescription}>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Total balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {typeof xValues[selectedDay.point?.series.name]
                    ?.totalBalance[selectedDay.point.x] === 'number'
                    ? currencyFormatter(xValues[selectedDay.point?.series.name]
                      ?.totalBalance[selectedDay.point.x] || 0)
                    : currencyFormatter(xValues[selectedDay.point?.series.name]
                      ?.totalBalance[selectedDay.point.x].y || 0)
                  }
                </Typography>
              </Box>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Account balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {currencyFormatter(xValues[selectedDay.point?.series?.name]
                    ?.cashBalance[selectedDay.point.x] || 0)}
                </Typography>
              </Box>
              <Box className={classes.titleAndStockDescription}>
                <Typography
                  fontWeight={500}
                >
                  Portfolio balance:
                </Typography>
                <Typography
                  color='textSecondary'
                  fontWeight={700}
                >
                  {currencyFormatter(xValues[selectedDay.point?.series?.name]
                    ?.portfolioBalance[selectedDay.point.x] || 0)}
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.modalDivider} />
            {Object.keys(selectedDetail.dayHoldings).length > 0
              && <TableContainer sx={{ maxHeight: 260, height: '100%' }}>
                <Table stickyHeader sx={{ backgroundColor: 'white' }}>
                  <TableHead>
                    <TableRow >
                      <TableCell className={classes.tableHeaderCell}>
                        Stock ticker
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Stock name
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Price EOD
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Num of stocks
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        Total value
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>
                        % of portfolio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDetail.dayHoldings
                      && Object.values(selectedDetail.dayHoldings)
                        .filter((asset) => asset.quantity > 0)
                        .map((
                          item,
                          index,
                        ) => {
                          const totalStockValue: number = item.quantity
                            * selectedDetail.prices[item.assetId];
                          return (
                            <TableRow
                              key={index}
                            >
                              <TableCell className={classes.tableBodyCell}>
                                {item.ticker}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {item.name}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`$ ${decimalsRound(selectedDetail.prices[item.assetId], 2)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`${decimalsRound(item.quantity, 4)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`$ ${decimalsRound(totalStockValue, 2)}`}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {`${decimalsRound((totalStockValue / xValues[selectedDay.point?.series.name]
                                  ?.portfolioBalance[selectedDay.point.x]) * 100 || 0, 2)} %`}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>

            }
            {Object.keys(selectedDetail.dayHoldings).length === 0
              && <Box
                textAlign='center'
              >
                No holdings in the portfolio
              </Box>

            }
          </CardContent>
        </Card>
      </Modal>
    </Box>
  );
}

BalanceChart.propTypes = {
  className: PropTypes.string,
};

export default BalanceChart;
