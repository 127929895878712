import React, { FunctionComponent, ImgHTMLAttributes } from 'react';

interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  width: string;
  height: string;
}

const Logo: FunctionComponent<LogoProps> = ({ height, width, ...rest }) => (
    <img
      alt="Logo"
      // eslint-disable-next-line global-require
      src={require('../../assets/images/logo.png')}
      height={height}
      width={width}
      {...rest}
    />
);

export default Logo;
