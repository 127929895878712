import { io } from 'socket.io-client';

const urlPath: string = window.location.hostname;
let url = 'https://2wr.tradesilike.com';
if (urlPath === 'localhost') {
  url = 'http://localhost:8080';
}

const socket = new (io as any)(url);

export default socket;
